import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import FilledInput from '@mui/material/FilledInput';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormControl, InputLabel, TextField } from '@mui/material';
import { MAX_CATALOG_DESCRIPTION_LENGTH } from '../../../shared/util/constant';
import images from '../../../assets/images';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCatalog,
  validateCatalogForm,
} from '../../../shared/services/common.service';
import { companyState } from '../../../shared/store/slices/userSlice';
import { useImageCrop } from '../../../shared/hooks/UseImageCrop';
import ReactCrop from 'react-image-crop';
import LoaderComp from '../../../shared/shared-comp/LoaderComp';
import { compressImage } from '../../../shared/util/util';

function EditTemplateDialog({
  catalog,
  editTemplate,
  closeEditTemplateDialog,
  setCatalogs,
  setFilteredCatalogs,
}) {
  const dispatch = useDispatch();
  const companySel = useSelector(companyState);

  const [errors, setErrors] = useState({
    name: '',
    description: '',
    thumbnail: '',
  });
  const [updating, setUpdating] = useState(false);
  const [catalogParams, setCatalogParams] = useState({
    title: catalog?.name || '',
    description: catalog?.description || '',
    app_builder_icon: catalog?.thumbnail || '',
    app_builder_icon_file: null,
  });
  const [isDragOver, setIsDragOver] = useState(false);

  const {
    imgRef,
    previewCanvasRef,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    onImageLoad,
  } = useImageCrop();

  const handleModalClose = (e, reason) => {
    if (reason && reason == 'backdropClick') return;
    closeEditTemplateDialog();
  };

  const handleForce = (files: FileList) => {
    if (files?.length) {
      const file = files[0];
      setCatalogParams((prev) => ({
        ...prev,
        app_builder_icon_file: file,
        app_builder_icon: URL.createObjectURL(file),
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setCatalogParams((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!validateCatalogForm(catalogParams, setErrors)) return;

    const formData = new FormData();
    formData.append('title', catalogParams.title);
    formData.append('description', catalogParams.description);

    console.log('completedCrop', completedCrop);
    console.log(
      'catalogParams.app_builder_icon_file',
      catalogParams.app_builder_icon_file
    );
    console.log(
      'catalogParams.app_builder_icon',
      catalogParams.app_builder_icon
    );

    let imageFile = catalogParams.app_builder_icon_file;

    if (catalogParams.app_builder_icon_file && completedCrop) {
      const canvas = previewCanvasRef.current;
      const response = await fetch(canvas.toDataURL());
      const blob = await response.blob();

      const compressedImage = (await compressImage(blob, {
        quality: 0.8,
        type: 'image/jpeg',
      })) as Blob;

      imageFile = new File([compressedImage], 'thumbnail.jpg', {
        type: 'image/jpeg',
      });
    }

    if (imageFile) {
      formData.append('new_app_builder_icon', imageFile);
    }

    console.log('image file', imageFile);

    try {
      setUpdating(true);
      const response = await updateCatalog(
        companySel?.id,
        catalog?.id,
        formData,
        dispatch
      );
      if (response?.['catalog']) {
        setCatalogs((prev) =>
          prev.map((cat) =>
            cat.id === catalog?.id
              ? { ...response?.['catalog'], id: catalog?.id }
              : cat
          )
        );
        setFilteredCatalogs((prev) =>
          prev.map((cat) =>
            cat.id === catalog?.id
              ? { ...response?.['catalog'], id: catalog?.id }
              : cat
          )
        );
      }
      closeEditTemplateDialog();
    } catch (error) {
      console.error('Error updating catalog:', error);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <Dialog open={editTemplate} onClose={handleModalClose}>
      <DialogContent className="">
        <div
          aria-label="edit-modal"
          className="modal-body edit-template-dialog"
        >
          <div className="">
            <h2>Edit template</h2>
          </div>
          <p>
            Templates let you reuse common journeys and features. You can use
            this template to create new buildcards faster in Studio.
          </p>
          <div className="form-group">
            <div className={`form-group ${errors?.name ? 'has-error' : ''}`}>
              <FormControl
                className={errors.name ? 'has-error' : ''}
                variant="filled"
              >
                <InputLabel>Template name</InputLabel>
                <FilledInput
                  inputProps={{ 'data-testid': 'first_name' }}
                  type="text"
                  value={catalogParams.title}
                  name="title"
                  onChange={handleInputChange}
                />
                {errors.name && (
                  <span className="form-error">{errors.name}</span>
                )}
              </FormControl>
            </div>
          </div>

          <div
            className={`form-group textarea-box ${errors?.name ? 'has-error' : ''}`}
          >
            <div className="num">
              {catalogParams.description.length}/
              {MAX_CATALOG_DESCRIPTION_LENGTH}
            </div>
            <TextField
              id="outlined-basic"
              type="text"
              label="Describe what this template is about"
              multiline
              rows={4}
              variant="outlined"
              name="description"
              value={catalogParams.description}
              onChange={handleInputChange}
              className="custom-textarea top-spacing"
            />
            {errors.description && (
              <span className="form-error">{errors.description}</span>
            )}
          </div>

          <div className="form-group">
            <label>Thumbnail</label>
            <div className="d-flex align-items-center">
              <div className="image-crop-container">
                {catalogParams?.app_builder_icon ? (
                  catalogParams.app_builder_icon_file ? (
                    // Show cropping interface only for custom uploaded images
                    <ReactCrop
                      crop={crop}
                      onChange={(_, percentCrop) => setCrop(percentCrop)}
                      onComplete={(c) => setCompletedCrop(c)}
                      aspect={1}
                      minWidth={56}
                      minHeight={56}
                    >
                      <img
                        ref={imgRef}
                        alt="Crop me"
                        src={catalogParams.app_builder_icon}
                        onLoad={onImageLoad}
                      />
                    </ReactCrop>
                  ) : (
                    <img
                      src={catalogParams.app_builder_icon}
                      alt="catalog icon"
                    />
                  )
                ) : (
                  <img src={images.catalogSw} alt="catalog icon" />
                )}
              </div>
              <canvas
                ref={previewCanvasRef}
                style={{
                  width: 56,
                  height: 56,
                  display: completedCrop ? 'block' : 'none',
                  marginLeft: '1rem',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                }}
              />
              <span className="or">OR</span>
              <div
                data-testid="drop-area"
                className={'file-uploading'}
                onDragLeave={() => setIsDragOver(false)}
                onDragOver={(e) => {
                  e.preventDefault();
                  setIsDragOver(true);
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  handleForce(e.dataTransfer.files);
                }}
              >
                <div className="d-flex justify-content-center">
                  <em className="thumb">
                    <img src={images.cloudUpload} alt="upload" />
                  </em>
                  <span>Drop image or</span>
                  <a href={void 0} className="browse-files-link example-csv">
                    <input
                      type="file"
                      className="hidden-input"
                      accept=".jpeg, .jpg, .png"
                      onChange={(e) => {
                        e.preventDefault();
                        handleForce(e.target.files);
                      }}
                    />
                    browse file
                  </a>
                </div>
                <p>Image size should be 56 x 56</p>
              </div>
            </div>
            {errors.thumbnail && (
              <span className="form-error">{errors.thumbnail}</span>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button
            aria-label="close-delete-dialog"
            variant="contained"
            className="sm-btn grey-btn"
            onClick={closeEditTemplateDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="green-btn sm-btn"
            onClick={handleSubmit}
            disabled={updating}
          >
            {updating ? <LoaderComp /> : 'Save changes'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default EditTemplateDialog;
