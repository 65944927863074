import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { fetchCatalogDetails } from '../../../shared/services/common.service';
import images from '../../../assets/images';
import { Menu, MenuItem } from '@mui/material';
import { Button } from '@mui/material';
import DeleteCatalogueDialog from './DeleteCatalogueDialog';
import { CatalogShow } from './CatalogShow';
import EditTemplateDialog from './EditTemplateDialog';
import { isAllowedTo } from '../../../shared/util/util';
import {
  permissionState,
  userPermissionState,
} from '../../../shared/store/slices/userSlice';

const CatalogCard = ({
  catalog,
  anchorEpdf,
  setAnchorEpdf,
  companySel,
  setCatalogs,
  setFilteredCatalogs,
}) => {
  const [openCatalog, setOpenCatalog] = useState(false);
  const [editTemplate, setEditTemplate] = useState(false);
  const [catalogDetails, setCatalogDetails] = useState(null);
  const [deleteCatalogue, setDeleteCatalogue] = useState(false);
  const [catalogDetailsLoading, setCatalogDetailsLoading] = useState(false);

  const dispatch = useDispatch();
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);

  const handleClickOpen = async () => {
    setOpenCatalog(true);
    setCatalogDetailsLoading(true);
    const response = await fetchCatalogDetails(
      companySel?.id,
      catalog?.id,
      dispatch
    );
    if (response?.['catalog']) {
      setCatalogDetails(response?.['catalog']);
    } else {
      handleCloseCatalog();
    }
    setCatalogDetailsLoading(false);
  };

  const handleCloseCatalog = () => {
    setOpenCatalog(false);
  };

  const handleClosepdf = (e) => {
    e.stopPropagation();
    setAnchorEpdf(null);
  };

  const handleClick = (idx, event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEpdf({ [idx]: event.currentTarget });
  };

  const closeDeleteCatalogueDialog = () => {
    setDeleteCatalogue(false);
  };

  const closeEditTemplateDialog = () => {
    setEditTemplate(false);
  };

  return (
    <div>
      <div
        className="catalog-card"
        data-testid="catalog-card"
        onClick={handleClickOpen}
      >
        <div>
          <div className="catalog-head">
            <div className="title">
              <div className="brand-iconbx">
                <img src={catalog.thumbnail} alt="catalog icon" />
              </div>
              <span>Created by {catalog.created_by}</span>
              <h3>{catalog?.name}</h3>
            </div>
            <Button
              className="xs-btn is-icon-btn three"
              id="basic-button"
              data-testid="three-dots-btn"
              variant="outlined"
              aria-controls={openCatalog ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openCatalog ? 'true' : undefined}
              onClick={(e) => e?.stopPropagation()}
            >
              <em
                onClick={(e) => handleClick(catalog?.id, e)}
                className="icon be-vertical-dots"
                style={{ display: 'flex' }}
              ></em>
            </Button>
            <Menu
              className="three-dot-menu-paper"
              id="basic-menu"
              anchorEl={anchorEpdf ? anchorEpdf[catalog?.id] : null}
              open={Boolean(anchorEpdf ? anchorEpdf[catalog?.id] : null)}
              onClose={handleClosepdf}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem
                className="toggle-menu-item-row"
                onClick={(e) => {
                  e?.stopPropagation();
                  handleClickOpen();
                  setAnchorEpdf(null);
                }}
              >
                <em className="icon be-eye"></em> View details
              </MenuItem>
              {isAllowedTo(
                'update',
                'catalog',
                permissions,
                { id: catalog?.id, type: 'Catalog' },
                userPermissions
              ) && (
                <MenuItem
                  className="toggle-menu-item-row"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditTemplate(true);
                  }}
                >
                  <em className="icon be-edit"></em> Edit template
                </MenuItem>
              )}
              {isAllowedTo('destroy', 'catalog', permissions) && (
                <MenuItem
                  className="toggle-menu-item-row"
                  aria-label="archive-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteCatalogue(true);
                  }}
                >
                  <em className="icon ">
                    <img src={images.deleteIcon} alt="catalog icon" />
                  </em>
                  Delete
                </MenuItem>
              )}
            </Menu>
          </div>
          <div className="middle">
            <p>{catalog?.description}</p>
          </div>
        </div>
        <div className="catalog-footer">
          <div className="actionbx">
            <span>Journey</span>
            <h5>{catalog?.journey_count || 0}</h5>
          </div>
          <div className="actionbx">
            <span>Features</span>
            <h5>{catalog?.feature_count || 0}</h5>
          </div>
          <div className="actionbx">
            <span>CREDITS</span>
            <h5>{catalog?.price || 0}</h5>
          </div>
        </div>
      </div>

      <CatalogShow
        {...{
          openCatalog,
          handleCloseCatalog,
          catalogDetails,
          catalogDetailsLoading,
        }}
      />

      <DeleteCatalogueDialog
        {...{
          deleteCatalogue,
          closeDeleteCatalogueDialog,
          catalog,
          companySel,
          setCatalogs,
          setFilteredCatalogs,
        }}
      />
      <EditTemplateDialog
        {...{
          catalog,
          editTemplate,
          closeEditTemplateDialog,
          setCatalogs,
          setFilteredCatalogs,
        }}
      />
    </div>
  );
};

export default CatalogCard;
