import React, { useEffect, useState } from 'react';
import images from '../assets/images';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getInitials, globalError } from '../shared/util/util';
import moment from 'moment';
import instance from '../shared/interceptor';
import { NOTIFICATION_DETAIL } from '../shared/util/constant';
import { useDispatch, useSelector } from 'react-redux';
import {
  addUserData,
  companyState,
  userState,
} from '../shared/store/slices/userSlice';

function NotificationPanel({
  notificationPanel,
  notificationTabValue,
  setNotificationTabValue,
  getNotification,
  notification,
  setNotification,
  setNotify,
  isTokenPresent,
  notificationLoading,
  shouldVisible,
  notificationMetaData,
  routeTo,
}) {
  console.log(notificationTabValue);
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const userSel = useSelector(userState);

  const [allTabCount, setAllTabCount] = useState(0);

  useEffect(() => {
    if (notificationTabValue == 'all') {
      setAllTabCount(notificationMetaData?.totalCount);
    }
  }, [notificationTabValue, notificationMetaData?.totalCount]);

  const tabHandleChange = (event: React.SyntheticEvent, newValue: string) => {
    setNotificationTabValue(newValue);
  };

  const markAsRead = async (notificationIds) => {
    if (!notificationIds?.length) return;
    try {
      const url = `${NOTIFICATION_DETAIL.replace(':id', compSel?.id).replace(':user_id', userSel?.id)}/mark_as_read`;
      const payload = {
        notification_ids: [...notificationIds],
      };
      let markReadRes = await instance.patch(url, payload);
      let temp = notification.map((item) => {
        if (notificationIds.includes(item?.id)) {
          return { ...item, read: true };
        } else {
          return { ...item };
        }
      });
      setNotification(temp);
      let notifyRead = temp.some((item) => !item?.read);
      if (!notifyRead || notificationIds?.length > 1) {
        setNotify(false);
      }
      dispatch(
        addUserData({
          ...userSel,
          pending_notifications: markReadRes?.['pending_notifications'],
        })
      );
      isTokenPresent();
    } catch (e) {
      globalError(dispatch, e);
    }
  };

  //=========================================================================

  return (
    <div className="notifications-panel" id="notificationsPanel">
      <div>
        <div className="head-top">
          <span className="title">
            <img src={images.iconBell} alt="" />
            Notifications
          </span>
          <em
            className="be-close cion close-icon"
            onClick={(e) => {
              e.stopPropagation();
              notificationPanel?.classList?.remove('active');
            }}
          ></em>
        </div>

        <div className="notification-header">
          <div className="left">
            <TabContext value={notificationTabValue}>
              <Tabs
                value={notificationTabValue}
                onChange={tabHandleChange}
                aria-label="tabs"
              >
                <Tab
                  value="all"
                  label={`All (${allTabCount})`}
                  onClick={() => getNotification('all')}
                  aria-label="tab-all"
                />
                <Tab
                  value="new"
                  label={`Unread (${userSel?.pending_notifications_count})`}
                  onClick={() => getNotification('new')}
                  aria-label="tab-new"
                />
              </Tabs>
            </TabContext>
          </div>
          <div className="right">
            <span
              aria-label="mark-read-btn"
              className="mark-all-read"
              onClick={() => markAsRead(notification.map((item) => item?.id))}
            >
              <em className="be be-check-double"></em>
              <span>Mark all as read</span>
            </span>
          </div>
        </div>

        <div className="notifications-body">
          <TabContext value={notificationTabValue}>
            {!notification.length && !notificationLoading && shouldVisible && (
              <div className="no-data-wrapper notifications">
                <em className="thumb">
                  <img src={images.iconNoNotifications} alt="Empty" />
                </em>
                <h4 aria-label="no-notification">No notification</h4>
                <p>You don't have any new notification.</p>
              </div>
            )}
            {notificationLoading && (
              <div>
                <img
                  src={(images as any).buAnim}
                  alt="B"
                  style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    height: '80px',
                    width: '80px',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              </div>
            )}
            <TabPanel value="new" id="newNotificationTab">
              <InfiniteScroll
                dataLength={
                  notificationMetaData.nextPage * notificationMetaData.perPage
                }
                next={() => getNotification('new')}
                hasMore={notification?.length < notificationMetaData.totalCount}
                loader={
                  !notificationLoading && (
                    <div style={{ textAlign: 'center' }}>
                      <img
                        src={(images as any).buAnim}
                        alt="B"
                        style={{ height: '60px', width: '60px' }}
                      />
                    </div>
                  )
                }
                scrollableTarget="newNotificationTab"
              >
                {!notificationLoading &&
                  notification?.length > 0 &&
                  notification?.map((noti) => {
                    return (
                      <div
                        className="notification-row"
                        key={noti?.id}
                        style={{ cursor: 'pointer' }}
                        onClick={() => markAsRead([noti?.id])}
                      >
                        <div className="user-link">
                          <div className="left-content">
                            {!noti?.read && <span className="blue-dot"></span>}
                            <span className="user-avatar">
                              <span className="initials">
                                {getInitials(noti?.creator_name) ?? 'S'}
                              </span>
                            </span>
                            <div className="user-detail">
                              <div className="basic-info">
                                <div className="description-text">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: noti?.message?.replace(
                                        /<i href.*i>/,
                                        ''
                                      ),
                                    }}
                                  ></span>
                                  {noti?.message?.includes('<i href') && (
                                    <div className="btn-wrap">
                                      <Button
                                        className="dark-purple-btn"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          routeTo(noti);
                                          markAsRead([noti?.id]);
                                          notificationPanel?.classList?.remove(
                                            'active'
                                          );
                                        }}
                                      >
                                        {noti?.meta_data?.button?.text}
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="right-content">
                            <div className="menu-trigger">
                              <span className="time">
                                {moment(noti?.created_at).fromNow(true)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </InfiniteScroll>
            </TabPanel>
          </TabContext>
          <TabContext value={notificationTabValue}>
            <TabPanel value="all" id="allNotificationTab">
              <InfiniteScroll
                dataLength={
                  notificationMetaData.nextPage * notificationMetaData.perPage
                }
                next={() => getNotification('all')}
                hasMore={notification?.length < notificationMetaData.totalCount}
                loader={
                  !notificationLoading && (
                    <div style={{ textAlign: 'center' }}>
                      <img
                        src={(images as any).buAnim}
                        alt="B"
                        style={{ height: '60px', width: '60px' }}
                      />
                    </div>
                  )
                }
                scrollableTarget="allNotificationTab"
              >
                {!notificationLoading &&
                  notification?.map((noti) => {
                    return (
                      <div
                        className="notification-row"
                        key={noti?.id}
                        style={{ cursor: 'pointer' }}
                        onClick={() => markAsRead([noti?.id])}
                      >
                        <div className="user-link">
                          <div className="left-content">
                            {!noti?.read && <span className="blue-dot"></span>}
                            <span className="user-avatar">
                              {/* <img src={(images as any).supportAvatar} alt="Avatar" /> */}
                              <span className="initials">
                                {getInitials(noti?.creator_name) ?? 'S'}
                              </span>
                            </span>
                            <div className="user-detail">
                              <div className="basic-info">
                                <div className="description-text">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: noti?.message?.replace(
                                        /<i href.*i>/,
                                        ''
                                      ),
                                    }}
                                  ></span>
                                  {noti?.message?.includes('<i href') && (
                                    <div className="btn-wrap">
                                      <Button
                                        className="dark-purple-btn"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          routeTo(noti);
                                          notificationPanel?.classList?.remove(
                                            'active'
                                          );
                                        }}
                                      >
                                        {noti?.meta_data?.button?.text}
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="right-content">
                            <div className="menu-trigger">
                              <span className="time">
                                {moment(noti?.created_at).fromNow(true)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </InfiniteScroll>
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </div>
  );
}

export default NotificationPanel;
