import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { destroyCatalog } from '../../../shared/services/common.service';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import LoaderComp from '../../../shared/shared-comp/LoaderComp';

function DeleteCatalogueDialog({
  deleteCatalogue,
  closeDeleteCatalogueDialog,
  companySel,
  catalog,
  setCatalogs,
  setFilteredCatalogs,
}) {
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  const handleModalClose = (e, reason) => {
    if (reason && reason == 'backdropClick') return;
    closeDeleteDialog(e);
  };

  const handleDeleteCatalog = async () => {
    try {
      setDeleting(true);
      const resp = await destroyCatalog(companySel?.id, catalog?.id, dispatch);
      if (resp?.['catalog']) {
        setCatalogs((prev) => prev?.filter((item) => item?.id !== catalog?.id));
        setFilteredCatalogs((prev) =>
          prev?.filter((item) => item?.id !== catalog?.id)
        );
        closeDeleteCatalogueDialog();
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setDeleting(false);
    }
  };

  //============================================================================

  return (
    <Dialog open={deleteCatalogue} onClose={handleModalClose}>
      <DialogContent className="">
        {/* <span
          className="modal-close be-close"
          onClick={closeDeleteCatalogueDialog}
        ></span> */}
        <div aria-label="delete-modal" className="modal-body">
          <div className="">
            <h2>Are you sure you want to remove this template?</h2>
          </div>
          <p>
            This template will be permanently deleted and can no longer be used
            to create Buildcards. This action can't be undone.
          </p>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button
            aria-label="close-delete-dialog"
            variant="contained"
            className="sm-btn grey-btn"
            onClick={closeDeleteCatalogueDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="red-btn sm-btn"
            onClick={handleDeleteCatalog}
            disabled={deleting}
          >
            {deleting ? <LoaderComp data-testid="loader-comp" /> : 'Remove'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteCatalogueDialog;
function closeDeleteDialog(e: any) {
  throw new Error('Function not implemented.');
}
