import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import images from '../../../assets/images';
import instance from '../../../shared/interceptor';
import { getUserListWithoutPermission } from '../../../shared/services/common.service';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { COMPANY, SPACES } from '../../../shared/util/constant';
import {
  debounce,
  getFullname,
  globalError,
  setLoader,
} from '../../../shared/util/util';
import LoaderComp from '../../../shared/shared-comp/LoaderComp';

function CreateSpaceGoalDialog({
  openDialogue,
  handleClose,
  selectedGoal = null,
}) {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const params = useParams();
  const [formVal, setFormVal] = useState({
    name: selectedGoal?.name ?? '',
    description: selectedGoal?.description ?? '',
    due_date: selectedGoal?.due_date ?? '',
    owner: selectedGoal?.owner ?? null,
  });
  const [error, setError] = useState({ name: '', desc: '', owner: '' });
  const [openCal, setOpenCal] = useState(false);
  const [selectBusinessGoal, setSelectBusinessGoal] = useState([]);
  const [selectSearchBusinessGoal, setSelectSearchBusinessGoal] = useState([]);
  const [selectedGoals, setSelectedGoals] = useState(
    selectedGoal?.id ? selectedGoal?.company_goals : []
  );
  const [loading, setLoading] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openOwner, setOpenOwner] = useState(false);

  const createSpaceGoal = async () => {
    try {
      if (formVal?.name?.length > 50) {
        return setError({
          ...error,
          name: 'Goal name should be of less than 50 characters',
        });
      }
      if (formVal?.description?.length > 250) {
        return setError({
          ...error,
          desc: 'Goal description should be of less than 250 characters',
        });
      }
      setLoader(dispatch, true);
      let url;
      let payload;
      if (selectedGoal?.id) {
        url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/space_goals/${selectedGoal?.id}`;
        payload = {
          space_goal: {
            name: formVal?.name,
            description: formVal?.description,
            due_date: moment(formVal?.due_date).format('YYYY-MM-DD'),
            owner_id: formVal?.owner?.id ?? null,
            company_goal_ids: selectedGoals.map((goal) => goal?.id),
          },
        };
        await instance.patch(url, payload);
        dispatch(setSuccessMsg(`Goal updated successfully.`));
      } else {
        url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/space_goals`;
        payload = {
          space_goal: {
            name: formVal?.name,
            description: formVal?.description,
            due_date: moment(formVal?.due_date).format('YYYY-MM-DD'),
            owner_id: formVal?.owner?.id ?? null,
            company_goal_ids: selectedGoals.map((goal) => goal?.id),
          },
        };
        await instance.post(url, payload);
        dispatch(setSuccessMsg(`Goal created successfully.`));
      }
      handleClose(null, true);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  };

  const getUserList = async (query) => {
    const userListRes = await getUserListWithoutPermission(
      compSel,
      query,
      dispatch,
      setLoading
    );
    setMemberList(userListRes?.['users'] ?? []);
  };

  const searchMembers = debounce((event) => {
    setOpenOwner(true);
    getUserList(event.target.value);
  });

  const searchBusinessGoal = debounce(async (val) => {
    try {
      setOpen(true);
      setLoading(true);
      let url = `${COMPANY}/${compSel?.id}/company_goals`;
      if (val) {
        url += `?q=${val}`;
      }
      let res = await instance.get(url);
      setSelectBusinessGoal(
        res['company_goals']?.filter((goal) => !goal?.archive)
      );
      setSelectSearchBusinessGoal(
        res['company_goals']?.filter((goal) => !goal?.archive)
      );
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoading(false);
    }
  });

  const filterBusinessGoals = (value) => {
    if (!value) {
      setSelectBusinessGoal(selectSearchBusinessGoal);
      selectSearchBusinessGoal?.length ? setOpen(true) : setOpen(false);
      return;
    }
    setSelectBusinessGoal(
      selectSearchBusinessGoal?.filter((item) =>
        item?.name?.toLowerCase()?.includes(value?.toLowerCase())
      )
    );
  };

  const addToSelected = (value) => {
    let val = [...new Map(value.map((item) => [item['id'], item])).values()];
    setSelectedGoals(val);
  };

  const handleDelete = (idx) => {
    let tempArr = [...selectedGoals];
    tempArr.splice(idx, 1);
    setSelectedGoals(tempArr);
  };

  const resetErrorObj = () => {
    setError({ name: '', desc: '', owner: '' });
  };

  const handleModalClose = (e, reason) => {
    if (reason && reason == 'backdropClick') return;
    handleClose(e, false);
  };
  const CustomPopper = styled(Popper)(({ theme }) => ({
    '&[data-popper-placement*="top"]': {
      inset: '106% 0px auto 0px !important', // Ensures popper opens at the bottom
    },
  }));

  //====================================================

  return (
    <Dialog
      open={openDialogue}
      onClose={handleModalClose}
      className="business-goal-modal"
      data-testid="create-space-dialog"
    >
      <DialogContent className="business-goal-body-wrap">
        <div className="modal-body">
          <div className="modal-title">
            <h2>{selectedGoal?.id ? 'Edit' : 'Create'} Space goal</h2>
          </div>
          <div className="modal-form">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <FormControl>
                    <InputLabel>Name</InputLabel>
                    <FilledInput
                      inputProps={{ 'data-testid': 'goal-name' }}
                      type="text"
                      placeholder=""
                      value={formVal?.name}
                      onChange={(e) => {
                        setFormVal({ ...formVal, name: e?.target?.value });
                        resetErrorObj();
                      }}
                    />
                  </FormControl>
                  <span className="form-error">{error?.['name']}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <TextField
                    id=""
                    label="Description"
                    multiline
                    rows={4}
                    variant="outlined"
                    name="Description"
                    value={formVal?.description}
                    className="custom-textarea top-spacing"
                    inputProps={{ 'data-testid': 'goal-desc' }}
                    onChange={(e) => {
                      setFormVal({ ...formVal, description: e?.target?.value });
                      resetErrorObj();
                    }}
                  />
                  <span className="form-error">{error?.['desc']}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <div className="date-picker-wrap">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        readOnly={!openCal}
                        open={openCal}
                        onClose={() => setOpenCal(false)}
                        format="DD/MM/YYYY"
                        disablePast
                        label="When should this goal be completed?"
                        className="basic-date-picker"
                        slotProps={{
                          textField: {
                            size: 'small',
                            onClick: () => setOpenCal(true),
                            error: false,
                          },
                          popper: {
                            className: 'sm-datepicker-popper',
                          },
                        }}
                        value={moment(formVal?.due_date) ?? ''}
                        onChange={(e) => {
                          setFormVal((prev) => ({
                            ...prev,
                            due_date: e as any,
                          }));
                          resetErrorObj();
                        }}
                      />
                    </LocalizationProvider>
                    <span className="form-error"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <FormControl
                    variant="filled"
                    className={error['owner'] ? 'input-error' : ''}
                  >
                    <Autocomplete
                      selectOnFocus
                      clearOnBlur
                      data-testid="autocomplete-create-goal-search"
                      value={formVal?.owner}
                      id="searchMembersDropdown"
                      className={`${error['owner'] ? 'input-error' : ''} search-members-dropdown`}
                      classes={{ popper: 'search-dropdown-popper' }}
                      onChange={(event, value) => {
                        setFormVal((prev) => ({ ...prev, owner: value }));
                        resetErrorObj();
                      }}
                      filterOptions={(options) => options}
                      options={memberList}
                      autoHighlight
                      getOptionLabel={(option) =>
                        getFullname(option?.first_name, option?.last_name) ??
                        option?.email
                      }
                      open={openOwner}
                      onBlur={() => setOpenOwner(false)}
                      noOptionsText={
                        loading ? (
                          <LoaderComp
                            borderLessStyle={{
                              'max-width': '5%',
                              margin: '1rem 0.6rem',
                            }}
                          />
                        ) : (
                          <div style={{ margin: '0.3rem 0' }}>
                            No owner found
                          </div>
                        )
                      }
                      popupIcon={<em className="be-search"></em>}
                      renderOption={(props, option, { selected }) => (
                        <Box component="span" {...props} key={option?.id}>
                          <div className="search-members-dropdown">
                            <div className="left-side">
                              {option?.profile_picture?.profile_picture_url ? (
                                <img
                                  alt="profile-pic"
                                  className="initials"
                                  src={
                                    option?.profile_picture?.profile_picture_url
                                  }
                                />
                              ) : (
                                <span className="initials">
                                  {option?.first_name?.[0] ?? (
                                    <em className="icon be-user"></em>
                                  )}
                                </span>
                              )}
                              <span className="user-description">
                                <span className="name">
                                  {getFullname(
                                    option?.first_name,
                                    option?.last_name
                                  ) ?? option?.email}
                                </span>
                                {getFullname(
                                  option?.first_name,
                                  option?.last_name
                                ) && (
                                  <span className="email">
                                    ({option?.email})
                                  </span>
                                )}
                              </span>
                            </div>
                          </div>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          inputProps={{
                            'data-testid': 'create-goal-text-field',
                          }}
                          {...params}
                          className="search-input-box"
                          label="Goal owner"
                          variant="outlined"
                          onChange={(event) => searchMembers(event)}
                        />
                      )}
                    />
                  </FormControl>
                  <span className="form-error">{error['owner']}</span>
                </div>
              </div>
            </div>
            <div className="row mt-24">
              <div className="col-sm-12">
                <h6>Align with Business goals</h6>
                <div className="form-group searchbar-wrap">
                  <FormControl>
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      filterOptions={(options) => options}
                      isOptionEqualToValue={(option, value) =>
                        option.id == value.id
                      }
                      id=""
                      PopperComponent={CustomPopper}
                      value={selectedGoals}
                      options={selectBusinessGoal}
                      autoHighlight
                      onChange={(event, value) => {
                        addToSelected(value);
                        resetErrorObj();
                      }}
                      classes={{
                        popper: 'search-dropdown-popper create-goal-dropdown',
                      }}
                      open={open}
                      onBlur={() => setOpen(false)}
                      noOptionsText={
                        loading ? (
                          <LoaderComp
                            borderLessStyle={{
                              'max-width': '5%',
                              margin: '1rem 0.6rem',
                            }}
                          />
                        ) : (
                          <div style={{ margin: '0.3rem 0' }}>
                            No goal found
                          </div>
                        )
                      }
                      getOptionLabel={(option) => option?.name}
                      renderTags={(value: string[], getTagProps) => ''}
                      renderOption={(props, option, { selected }) => (
                        <Box component="span" {...props} key={option?.id}>
                          <div className="search-goals-dropdown">
                            <div className="left-side">
                              <span className="user-description">
                                <span className="name">
                                  {option?.name || ''}
                                </span>
                              </span>
                            </div>
                            <div className="right-side">
                              <Checkbox
                                className="square-checkbox"
                                checked={selected}
                                icon={
                                  <img src={images.checkboxUnchecked} alt="" />
                                }
                                checkedIcon={
                                  <img src={images.checkboxChecked} alt="" />
                                }
                              />
                            </div>
                          </div>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          label={
                            selectedGoals?.filter((go) => !go?.archive)
                              ?.length > 0
                              ? `${selectedGoals?.filter((go) => !go?.archive)?.length} selected`
                              : 'Select business goals'
                          }
                          {...params}
                          onClick={(e) =>
                            searchBusinessGoal((e?.target as any)?.value)
                          }
                          onChange={(e) => {
                            filterBusinessGoals(e?.target?.value);
                            resetErrorObj();
                          }}
                          className="search-input-box"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <div className="chips-wrapper list-items">
                    <ul>
                      {selectedGoals?.filter((go) => !go?.archive)?.length >
                        0 &&
                        selectedGoals
                          ?.filter((go) => !go?.archive)
                          ?.map((item, idx) => (
                            <li key={item?.id}>
                              <span>{item?.name}</span>{' '}
                              <em
                                className="icon be-close"
                                onClick={() => handleDelete(idx)}
                              ></em>
                            </li>
                          ))}
                    </ul>
                  </div>
                  {!selectedGoals?.length && (
                    <div className="has-right-data checkbox-container">
                      <span>No Business goals selected yet. </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button
            variant="contained"
            className="sm-btn grey-btn"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            data-testid="add-goal-btn"
            variant="contained"
            className="sm-btn green-btn"
            disabled={!formVal?.name || !formVal?.due_date}
            onClick={createSpaceGoal}
          >
            {selectedGoal?.id ? 'Save' : 'Create'} goal
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default CreateSpaceGoalDialog;
