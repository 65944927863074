import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import FilledInput from '@mui/material/FilledInput';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  TextField,
} from '@mui/material';
import { MAX_CATALOG_DESCRIPTION_LENGTH } from '../../../shared/util/constant';
import images from '../../../assets/images';
import { useRef, useState, useEffect } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {
  compressImage,
  convertDefaultIconToFile,
} from '../../../shared/util/util';
import {
  createCatalog,
  validateCatalogForm,
  createDefaultIcon,
  loadRubikFont,
} from '../../../shared/services/common.service';
import { companyState } from '../../../shared/store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import LoaderComp from '../../../shared/shared-comp/LoaderComp';
import { useImageCrop } from '../../../shared/hooks/UseImageCrop';

function CreateTemplateDialog({
  buildCard,
  createTemplate,
  closeCreateTemplateDialog,
  updateBuildCardAttributes,
}) {
  const [errors, setErrors] = useState({
    name: '',
    description: '',
    thumbnail: '',
  });
  const [catalogParams, setCatalogParams] = useState(() => {
    const defaultIcon = createDefaultIcon(buildCard?.alias_name || '');
    return {
      title: `Template of ${buildCard?.alias_name}`,
      description: '',
      app_builder_icon: defaultIcon,
      app_builder_icon_file: null,
    };
  });
  const [creating, setCreating] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);

  const companySel = useSelector(companyState);
  const dispatch = useDispatch();

  const {
    imgRef,
    previewCanvasRef,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    onImageLoad,
  } = useImageCrop();

  useEffect(() => {
    const initFont = async () => {
      const fontLoaded = await loadRubikFont();
      if (fontLoaded) {
        const defaultIcon = createDefaultIcon(buildCard?.alias_name || '');
        setCatalogParams((prev) => ({
          ...prev,
          app_builder_icon: defaultIcon,
        }));
      }
    };
    initFont();
  }, [buildCard?.alias_name]);

  const handleModalClose = (e, reason) => {
    if (reason && reason == 'backdropClick') return;
    closeCreateTemplateDialog(e);
  };

  const handleForce = (files: FileList) => {
    if (files?.length) {
      const file = files[0];
      setCatalogParams((prev) => ({
        ...prev,
        app_builder_icon_file: file,
        app_builder_icon: URL.createObjectURL(file),
      }));
    }
  };

  const handleCatalogCreation = async () => {
    try {
      let imageFile = catalogParams.app_builder_icon_file;

      // If we have a custom uploaded image that needs cropping
      if (catalogParams.app_builder_icon_file && completedCrop) {
        const canvas = previewCanvasRef.current;
        const response = await fetch(canvas.toDataURL());
        const blob = await response.blob();

        const compressedImage = (await compressImage(blob, {
          quality: 0.8,
          type: 'image/jpeg',
        })) as Blob;

        imageFile = new File([compressedImage], 'thumbnail.jpg', {
          type: 'image/jpeg',
        });
      }
      // If we're using the default icon, convert it to a file
      else if (
        catalogParams.app_builder_icon &&
        !catalogParams.app_builder_icon_file
      ) {
        imageFile = await convertDefaultIconToFile(
          catalogParams.app_builder_icon
        );
      }

      if (!validateCatalogForm(catalogParams, setErrors)) return;

      const formData = new FormData();
      formData.append('title', catalogParams.title);
      formData.append('description', catalogParams.description);
      formData.append('catalogable_type', 'App');
      formData.append('build_card_id', buildCard?.enterprise_id);

      if (imageFile) {
        formData.append('app_builder_icon', imageFile);
      }

      try {
        setCreating(true);
        const response = await createCatalog(
          companySel?.id,
          formData,
          dispatch
        );
        closeCreateTemplateDialog();
        updateBuildCardAttributes(buildCard?.id, {
          catalog_id: response?.['catalog']?.id,
        });
      } catch (error) {
        console.error('Error creating catalog:', error);
      } finally {
        setCreating(false);
      }
    } catch (error) {
      console.error('Error processing image:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCatalogParams((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  //============================================================================

  return (
    <Dialog open={createTemplate} onClose={handleModalClose}>
      <DialogContent className="">
        <span
          className="modal-close be-close"
          onClick={closeCreateTemplateDialog}
        ></span>
        <div
          aria-label="delete-modal"
          className="modal-body edit-template-dialog"
        >
          <div className="">
            <h2>Create template</h2>
          </div>
          <p>
            Templates let you reuse common journeys and features. You can use
            this template to create new buildcards faster in Studio.
          </p>
          <div className="form-group">
            <div className={`form-group ${errors?.name ? 'has-error' : ''}`}>
              <FormControl variant="filled">
                <InputLabel>Template name</InputLabel>
                {/* @ts-ignore */}
                <FilledInput
                  inputProps={{ 'data-testid': 'first_name' }}
                  type="text"
                  value={catalogParams.title}
                  name="title"
                  onChange={handleInputChange}
                />
                {errors.name && (
                  <span className="form-error" data-testid="name-error">
                    {errors.name}
                  </span>
                )}
              </FormControl>
            </div>
          </div>

          <div
            className={`form-group textarea-box ${errors?.description ? 'has-error' : ''}`}
          >
            <div className="num">
              {catalogParams.description.length}/
              {MAX_CATALOG_DESCRIPTION_LENGTH}
            </div>
            <TextField
              id="outlined-basic"
              type="text"
              label="Describe what this template is about"
              multiline
              rows={4}
              variant="outlined"
              name="description"
              value={catalogParams.description}
              onChange={handleInputChange}
              className="custom-textarea top-spacing"
            />
            {errors.description && (
              <span className="form-error" data-testid="desc-error">
                {errors.description}
              </span>
            )}
          </div>

          <div className="form-group">
            <label>
              Upload thumbnail (optional){' '}
              <a
                href="#"
                onClick={() => {
                  setCatalogParams((prev) => ({
                    ...prev,
                    app_builder_icon: createDefaultIcon(
                      buildCard?.alias_name || ''
                    ),
                    app_builder_icon_file: null,
                  }));
                  setCrop(undefined);
                  setCompletedCrop(undefined);
                }}
                style={{
                  fontWeight: '500',
                  position: 'relative',
                  top: '0.4rem',
                }}
                className="d-flex align-items-center"
              >
                <em className="icon be-refresh"></em>Reset to default
              </a>
            </label>
            <div className="d-flex align-items-center">
              <div className="image-crop-container">
                {catalogParams?.app_builder_icon ? (
                  catalogParams.app_builder_icon_file ? (
                    // Show cropping interface only for custom uploaded images
                    <ReactCrop
                      crop={crop}
                      onChange={(_, percentCrop) => setCrop(percentCrop)}
                      onComplete={(c) => setCompletedCrop(c)}
                      aspect={1}
                      minWidth={56}
                      minHeight={56}
                    >
                      <img
                        ref={imgRef}
                        alt="Crop me"
                        src={catalogParams.app_builder_icon}
                        onLoad={onImageLoad}
                      />
                    </ReactCrop>
                  ) : (
                    // Show default icon without cropping interface
                    <img
                      src={catalogParams.app_builder_icon}
                      alt="catalog icon"
                    />
                  )
                ) : (
                  <img src={images.catalogSw} alt="catalog icon" />
                )}
              </div>
              <canvas
                ref={previewCanvasRef}
                style={{
                  width: 56,
                  height: 56,
                  display: completedCrop ? 'block' : 'none',
                  marginLeft: '1rem',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                }}
              />
              <span className="or">OR</span>
              <div
                data-testid="drop-area"
                className={'file-uploading'}
                onDragLeave={() => setIsDragOver(false)}
                onDragOver={(e) => {
                  e.preventDefault();
                  setIsDragOver(true);
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  handleForce(e.dataTransfer.files);
                }}
              >
                <div className="d-flex justify-content-center">
                  <em className="thumb">
                    <img src={images.cloudUpload} alt="upload" />
                  </em>
                  <span>Drop image or</span>
                  <a href={void 0} className="browse-files-link example-csv">
                    <input
                      type="file"
                      className="hidden-input"
                      accept=".jpeg, .jpg, .png"
                      onChange={(e) => {
                        e.preventDefault();
                        handleForce(e.target.files);
                      }}
                    />
                    browse file
                  </a>
                </div>
                <p>Dimesion 56 x 56 | Max size 1 Mb</p>
              </div>
            </div>
            {errors.thumbnail && (
              <span className="form-error">{errors.thumbnail}</span>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button
            aria-label="close-delete-dialog"
            variant="contained"
            className="sm-btn grey-btn"
            onClick={closeCreateTemplateDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="green-btn sm-btn"
            data-testid="create-catalog-btn"
            onClick={handleCatalogCreation}
            disabled={creating}
          >
            {creating ? <LoaderComp /> : 'Save changes'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default CreateTemplateDialog;
function closeEditTemplateDialog(e: any) {
  throw new Error('Function not implemented.');
}
