import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import images from '../../assets/images';
import UnauthorizedAccess from '../../components/UnauthorizedAccess';
import LoaderComp from '../../shared/shared-comp/LoaderComp';
import { currentSpaceState } from '../../shared/store/slices/companySlice';
import {
  permissionState,
  userPermissionState,
  userState,
} from '../../shared/store/slices/userSlice';
import { isAllowedTo, isPrivateSpaceOwner } from '../../shared/util/util';
import '../company/credits/credits.scss';
import { buildcardConsumptionGraphData } from '../dashboard/dashboardGraphData';
import SpaceCreditModals from './SpaceCreditModals';

export default function SpaceCredits({ showLoading, spaceCreditSel }) {
  const currentSpaceSel = useSelector(currentSpaceState);
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);
  const userSel = useSelector(userState);
  const params = useParams();

  const [
    openCreditRequestHistoryDialogue,
    setOpenCreditRequestHistoryDialogue,
  ] = useState(false);
  const [openRequestCreditDialogue, setOpenRequestCreditDialogue] =
    useState(null);
  const [spaceCreditOverview, setSpaceCreditOverview] = useState(null);
  const [buildcardConsumption, setBuildcardConsumption] = useState(null);
  const [buildcardEstimation, setBuildcardEstimation] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const pendingSpaceCreditRequest =
    spaceCreditSel?.[0]?.['space_credit']?.['pending_space_credit_request'];
  const [openCancelCreditRequestDialogue, setOpenCancelCreditRequestDialogue] =
    useState(null);

  const entity = { id: params?.id, type: 'Space' };

  useEffect(() => {
    setSpaceCreditOverview(spaceCreditSel?.[0]?.['space_credit']);
    setBuildcardConsumption(spaceCreditSel?.[1]);
    setBuildcardEstimation(spaceCreditSel?.[2]);
  }, [
    pendingSpaceCreditRequest?.id,
    pendingSpaceCreditRequest?.credit_requested,
    spaceCreditSel?.length,
  ]);

  const handleClickOpen = () => {
    setOpenCreditRequestHistoryDialogue(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //=============================================================================
  return isAllowedTo(
    'read',
    'space_credit',
    permissions,
    entity,
    userPermissions
  ) && isPrivateSpaceOwner(currentSpaceSel, userSel) ? (
    <>
      <div
        className="credits-page spaces-credits-page"
        aria-label="space-credits"
      >
        <div className="page-container pb-0">
          <div className="main-content-wrap">
            <section className="overview-inner-section">
              <div className="page-content pb-0">
                {/* Space credit overview */}
                <div className="tabListView tab-scroll-spacing">
                  {/* No data wrapper  */}
                  {spaceCreditOverview &&
                    !spaceCreditOverview?.allocated_credits &&
                    !showLoading && (
                      <div className="no-data-wrapper mt-0">
                        <em className="thumb">
                          <img src={images.emptyStateIllustration} alt="Flag" />
                        </em>
                        <h4 aria-label="no-space">No credits yet</h4>
                        <p>
                          Your space has not been allocated with any credits
                          yet.
                        </p>
                        <Button
                          variant="contained"
                          className="xs-btn purple-btn btn-add-goal"
                          onClick={() =>
                            setOpenRequestCreditDialogue(spaceCreditOverview)
                          }
                        >
                          Request credits
                        </Button>
                      </div>
                    )}
                  <div className="section-row">
                    {showLoading || !spaceCreditOverview ? (
                      <LoaderComp border={true} style={{ height: '20vh' }} />
                    ) : (
                      spaceCreditOverview?.allocated_credits > 0 && (
                        <div className="current-allocations card">
                          <div className="title-wrap">
                            <h4>Space credit overview</h4>
                            <div className="button-wrap">
                              {spaceCreditOverview?.request_history ? (
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title="Request history"
                                  classes={{ popper: 'info-tooltip' }}
                                >
                                  <Button
                                    variant="outlined"
                                    className="xs-btn is-icon-btn icon"
                                    onClick={handleClickOpen}
                                  >
                                    <img
                                      src={images.iconHistory}
                                      alt="Request history"
                                    />
                                  </Button>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title="No request history"
                                  classes={{ popper: 'info-tooltip' }}
                                >
                                  <span>
                                    <Button
                                      variant="outlined"
                                      disabled
                                      className="xs-btn is-icon-btn icon"
                                    >
                                      <img
                                        src={images.iconHistory}
                                        alt="Request history"
                                      />
                                    </Button>
                                  </span>
                                </Tooltip>
                              )}

                              <Tooltip
                                arrow
                                placement="top"
                                title="Credit request history"
                                classes={{ popper: 'info-tooltip' }}
                              >
                                <Button
                                  variant="contained"
                                  className="xs-btn purple-btn"
                                  onClick={() =>
                                    setOpenRequestCreditDialogue(
                                      spaceCreditOverview
                                    )
                                  }
                                >
                                  Request credits
                                </Button>
                              </Tooltip>
                            </div>
                          </div>

                          <div className="progress-wrap">
                            <Box sx={{ width: '100%' }}>
                              <LinearProgress
                                variant="determinate"
                                value={
                                  (spaceCreditOverview?.consumed_credits /
                                    spaceCreditOverview?.allocated_credits) *
                                  100
                                }
                              />
                            </Box>
                          </div>
                          <div className="count-wrap">
                            <div className="left">
                              <span>
                                <em className="square-dot purple"></em>
                                <strong data-testid="consumed_credits">
                                  {Math.round(
                                    spaceCreditOverview?.consumed_credits
                                  )}
                                </strong>
                                &nbsp; Credits used
                              </span>
                              <span>
                                <em className="square-dot"></em>
                                <strong data-testid="credit_balance">
                                  {Math.round(
                                    spaceCreditOverview?.credit_balance
                                  )}
                                </strong>
                                &nbsp; Credit balance
                              </span>
                            </div>
                            <div className="right">
                              <span>
                                Total credits:{' '}
                                <strong data-testid="total_credits">
                                  &nbsp;
                                  {Math.round(
                                    spaceCreditOverview?.allocated_credits
                                  )}
                                </strong>
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>

                  {/* Active Buildcards consumption */}
                  <div className="section-row">
                    {showLoading || !spaceCreditOverview ? (
                      <LoaderComp border={true} style={{ height: '28vh' }} />
                    ) : (
                      buildcardConsumption?.total_build_cards_count > 0 && (
                        <div className="current-allocations card">
                          <div className="title-wrap">
                            <h4>Active Buildcard credits</h4>
                            <span>
                              {buildcardConsumption?.total_build_cards_count}{' '}
                              Buildcards
                            </span>
                          </div>
                          <div
                            className="graph-wrap"
                            data-testid="highcharts-graph"
                          >
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={buildcardConsumptionGraphData(
                                buildcardConsumption
                              )}
                            />
                            {/* <img src={(images as any).creditsGrapgh} alt="credits grapgh" /> */}
                          </div>
                        </div>
                      )
                    )}
                  </div>

                  {/* Draft Buildcards Estimated consumption */}
                  <div className="section-row">
                    {showLoading || !spaceCreditOverview ? (
                      <LoaderComp border={true} style={{ height: '10vh' }} />
                    ) : (
                      <div className="current-allocations card">
                        <div className="title-wrap">
                          <h4>Draft Buildcard: estimated credits</h4>
                          <span className="credit-consuption">
                            Estimated credits needed:{' '}
                            <span
                              className={`${buildcardEstimation?.total_estimated_credits > spaceCreditOverview?.credit_balance ? 'warning' : ''}`}
                            >
                              {Math.round(
                                buildcardEstimation?.total_estimated_credits
                              ) || 0}
                            </span>
                          </span>
                        </div>
                        <div className="credits-list">
                          {buildcardEstimation?.build_cards?.map(
                            (item, idx) => (
                              <div className="list-content" key={item?.id}>
                                <div className="title-wrap">
                                  <h5>{item?.name}</h5>
                                  {item?.space_goals?.length > 0 && (
                                    <div className="tag-wrapper">
                                      <span className="custom-tag">
                                        {item?.space_goals?.[0]?.name}
                                      </span>
                                      {item?.space_goals?.length > 1 && (
                                        <span
                                          className="custom-tag"
                                          onClick={(event) =>
                                            setAnchorEl({
                                              [idx]: event.currentTarget,
                                            })
                                          }
                                        >
                                          +{item?.space_goals?.length - 1}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                  <Menu
                                    className=""
                                    id="basic-menu"
                                    anchorEl={anchorEl?.[idx]}
                                    open={Boolean(anchorEl?.[idx])}
                                    onClose={handleClose}
                                    MenuListProps={{
                                      'aria-labelledby': 'basic-button',
                                    }}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                  >
                                    {item?.space_goals
                                      ?.slice(1, item?.space_goals?.length)
                                      ?.map((goal) => (
                                        <MenuItem
                                          key={goal?.id}
                                          className="custom-tag nrl-tag"
                                        >
                                          <span className="check">
                                            {goal?.name}
                                          </span>
                                        </MenuItem>
                                      ))}
                                  </Menu>
                                </div>
                                <div className="credit-estimated">
                                  <span>
                                    <strong>
                                      {Math.round(item?.estimated_credits)}
                                    </strong>{' '}
                                    Credit usage (estimated){' '}
                                  </span>
                                  {item?.over_estimated && (
                                    <Tooltip
                                      placement="top-start"
                                      arrow
                                      title={
                                        'We estimate this Buildcard requires more than the current credit balance available in your space.'
                                      }
                                      classes={{
                                        popper:
                                          'info-tooltip estimated-consuption-tooltip',
                                      }}
                                    >
                                      <img
                                        className="warning-icon"
                                        src={images.iconWarning}
                                        alt=""
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                                <div
                                  className={`${item?.over_estimated ? 'warning' : ''} progress-wrap`}
                                >
                                  <Box sx={{ width: '100%' }}>
                                    <LinearProgress
                                      variant="determinate"
                                      value={
                                        item?.over_estimated
                                          ? 100
                                          : (item?.estimated_credits /
                                              spaceCreditOverview?.credit_balance) *
                                            100
                                      }
                                    />
                                  </Box>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <Summary /> */}
            </section>
          </div>
        </div>
      </div>

      {(openRequestCreditDialogue ||
        openCreditRequestHistoryDialogue ||
        openCancelCreditRequestDialogue) && (
        <SpaceCreditModals
          {...{
            openRequestCreditDialogue,
            setOpenRequestCreditDialogue,
            openCreditRequestHistoryDialogue,
            setOpenCreditRequestHistoryDialogue,
            openCancelCreditRequestDialogue,
            setOpenCancelCreditRequestDialogue,
          }}
        />
      )}
    </>
  ) : (
    <UnauthorizedAccess />
  );
}
