import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import images from '../../../assets/images';
import instance from '../../../shared/interceptor';
import { getUserListWithoutPermission } from '../../../shared/services/common.service';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { COMPANY } from '../../../shared/util/constant';
import {
  debounce,
  getFullname,
  globalError,
  setLoader,
} from '../../../shared/util/util';
import LoaderComp from '../../../shared/shared-comp/LoaderComp';

function CreateBusinessGoalDialogue({
  openCreateDialogue,
  handleClose,
  goalDetail = null,
}) {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);

  const [openCal, setOpenCal] = useState(false);
  const [error, setError] = useState({
    name: '',
    desc: '',
    dueDate: '',
    owner: '',
  });
  const [loading, setLoading] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [formVal, setFormVal] = useState({
    name: goalDetail?.name ? goalDetail?.name : '',
    description: goalDetail?.description ? goalDetail?.description : '',
    private: goalDetail?.private ? goalDetail?.private : false,
    due_date: goalDetail?.due_date ? goalDetail?.due_date : '',
    owner: goalDetail?.owner ? goalDetail?.owner : null,
  });
  const [open, setOpen] = useState(false);

  function datePickerIcon() {
    return <em className="icon be-meetings"></em>;
  }

  const checkFormVal = () => {
    if (!formVal?.name?.trim() || formVal?.name?.length > 50) {
      return {
        title: 'name',
        valid: false,
        message:
          'Goal name should not be empty and should not exceed 50 characters.',
      };
    } else if (
      !formVal?.description?.trim() ||
      formVal?.description?.length > 250
    ) {
      return {
        title: 'desc',
        valid: false,
        message:
          'Goal description should not be empty and should not exceed 250 characters.',
      };
    } else if (!formVal?.due_date) {
      return {
        title: 'dueDate',
        valid: false,
        message: 'Enter the target date.',
      };
    }
    return { valid: true };
  };

  const getUserList = async (query) => {
    const userListRes = await getUserListWithoutPermission(
      compSel,
      query,
      dispatch,
      setLoading
    );
    setMemberList(userListRes?.['users'] ?? []);
  };

  const searchMembers = debounce((event) => {
    setOpen(true);
    getUserList(event.target.value);
  });

  const submitCreateForm = async () => {
    try {
      let isFormValid = checkFormVal();
      if (!isFormValid?.valid) {
        return setError({
          ...error,
          [isFormValid['title']]: isFormValid?.message,
        });
      }
      resetErrorObj();
      setLoader(dispatch, true);
      let payload;
      if (goalDetail) {
        payload = {
          company_goal: {
            ...formVal,
            due_date: moment(formVal?.due_date).format('YYYY-MM-DD'),
            owner_id: formVal?.owner?.id,
            status: goalDetail?.status,
          },
        };
      } else {
        payload = {
          company_goal: {
            ...formVal,
            due_date: moment(formVal?.due_date).format('YYYY-MM-DD'),
            owner_id: formVal?.owner?.id,
          },
        };
      }
      delete payload?.company_goal?.owner;
      if (goalDetail) {
        const url = `${COMPANY}/${compSel?.id}/company_goals/${goalDetail?.id}`;
        await instance.patch(url, payload);
        dispatch(setSuccessMsg('Goal updated successfully.'));
      } else {
        const url = `${COMPANY}/${compSel?.id}/company_goals`;
        await instance.post(url, payload);
        dispatch(setSuccessMsg('Goal created successfully.'));
      }
      handleClose(null, true);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  };

  const handleModalClose = (e, reason) => {
    if (reason && reason == 'backdropClick') return;
    handleClose(e, false);
  };

  const resetErrorObj = () => {
    setError({
      name: '',
      desc: '',
      dueDate: '',
      owner: '',
    });
  };

  //=======================================================
  return (
    <Dialog
      open={openCreateDialogue}
      onClose={handleModalClose}
      className="business-goal-modal"
    >
      <DialogContent className="business-goal-body-wrap">
        <div className="modal-body" aria-label="create-business-dialog">
          <div className="modal-title">
            <h2 aria-label="create-business-dialog-heading">
              {goalDetail ? 'Edit' : 'Create'} a business goal
            </h2>
          </div>
          <div className="modal-form">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <FormControl
                    className={`${error['name'] ? 'has-error' : ''}`}
                  >
                    <InputLabel>Name</InputLabel>
                    <FilledInput
                      data-testid="form-name"
                      type="text"
                      placeholder=""
                      value={formVal?.name}
                      onChange={(e) => {
                        setFormVal((prev) => ({
                          ...prev,
                          name: e?.target?.value,
                        }));
                        resetErrorObj();
                      }}
                    />
                  </FormControl>
                  <span className="form-error">{error['name']}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <TextField
                    data-testid="text-field"
                    id=""
                    label="Description"
                    multiline
                    value={formVal?.description}
                    onChange={(e) => {
                      setFormVal((prev) => ({
                        ...prev,
                        description: e?.target?.value,
                      }));
                      resetErrorObj();
                    }}
                    rows={4}
                    variant="outlined"
                    name="Description"
                    inputProps={{ 'data-testid': 'form-description' }}
                    className={`${error['desc'] ? 'input-error' : ''} custom-textarea top-spacing`}
                  />
                  <span className="form-error">{error['desc']}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <div className="date-picker-wrap">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        readOnly={!openCal}
                        open={openCal}
                        onClose={() => setOpenCal(false)}
                        format="DD/MM/YYYY"
                        disablePast
                        value={moment(formVal?.due_date) ?? ''}
                        label="When should this goal be completed?"
                        className={`${error['dueDate'] ? 'input-error' : ''} basic-date-picker`}
                        slots={{ openPickerIcon: datePickerIcon }}
                        slotProps={{
                          textField: {
                            size: 'small',
                            onClick: () => setOpenCal(true),
                            error: false,
                          },
                          popper: {
                            className: 'sm-datepicker-popper',
                          },
                        }}
                        onChange={(e) => {
                          setFormVal((prev) => ({
                            ...prev,
                            due_date: e as any,
                          }));
                          resetErrorObj();
                        }}
                        data-testid="goal-date-picker"
                      />
                    </LocalizationProvider>
                    <span className="form-error">{error['dueDate']}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <FormControl
                    variant="filled"
                    className={error['owner'] ? 'input-error' : ''}
                  >
                    <Autocomplete
                      data-testid="autocomplete-create-goal-search"
                      value={formVal?.owner}
                      id="searchMembersDropdown"
                      className={`${error['owner'] ? 'input-error' : ''} search-members-dropdown`}
                      classes={{ popper: 'search-dropdown-popper' }}
                      onChange={(event, value) => {
                        setFormVal((prev) => ({ ...prev, owner: value }));
                        resetErrorObj();
                      }}
                      filterOptions={(options) => options}
                      options={memberList}
                      autoHighlight
                      getOptionLabel={(option) =>
                        getFullname(option?.first_name, option?.last_name) ??
                        option?.email
                      }
                      popupIcon={<em className="be-search"></em>}
                      open={open}
                      onBlur={() => setOpen(false)}
                      noOptionsText={
                        loading ? (
                          <LoaderComp
                            borderLessStyle={{
                              'max-width': '5%',
                              margin: '1rem 0.6rem',
                            }}
                          />
                        ) : (
                          <div style={{ margin: '0.3rem 0' }}>
                            No Owner found
                          </div>
                        )
                      }
                      renderOption={(props, option, { selected }) => (
                        <Box component="span" {...props} key={option?.id}>
                          <div className="search-members-dropdown">
                            <div className="left-side">
                              {option?.profile_picture?.profile_picture_url ? (
                                <img
                                  alt="profile-pic"
                                  className="initials"
                                  src={
                                    option?.profile_picture?.profile_picture_url
                                  }
                                />
                              ) : (
                                <span className="initials">
                                  {option?.first_name?.[0] ?? (
                                    <em className="icon be-user"></em>
                                  )}
                                </span>
                              )}
                              <span className="user-description">
                                <span className="name">
                                  {getFullname(
                                    option?.first_name,
                                    option?.last_name
                                  ) ?? option?.email}
                                </span>
                                {getFullname(
                                  option?.first_name,
                                  option?.last_name
                                ) && (
                                  <span className="email">
                                    ({option?.email})
                                  </span>
                                )}
                              </span>
                            </div>
                          </div>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          inputProps={{
                            'data-testid': 'create-goal-text-field',
                          }}
                          {...params}
                          className="search-input-box"
                          label="Goal owner"
                          variant="outlined"
                          onChange={(event) => searchMembers(event)}
                        />
                      )}
                    />
                  </FormControl>
                  <span className="form-error">{error['owner']}</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="form-group mb-50">
                  <div className="has-right-data checkbox-container">
                    <FormControlLabel
                      className="checkbox-control"
                      control={
                        <Checkbox
                          data-testid="form-private-checkbox"
                          aria-label="checkbox"
                          checked={formVal?.private}
                          onChange={(e) =>
                            setFormVal((prev) => ({
                              ...prev,
                              private: e?.target?.checked,
                            }))
                          }
                          icon={<img src={images.checkboxUnchecked} alt="" />}
                          checkedIcon={
                            <img src={images.checkboxChecked} alt="" />
                          }
                        />
                      }
                      label={
                        <span className="make-private-label">
                          This is my private goal.
                        </span>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button
            data-testid="close-goal-dialog"
            variant="contained"
            className="sm-btn grey-btn"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            data-testid="create-edit-goal"
            disabled={!formVal?.name || !formVal?.due_date}
            variant="contained"
            className="sm-btn"
            onClick={submitCreateForm}
          >
            {goalDetail ? 'Save ' : 'Create '}goal
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default CreateBusinessGoalDialogue;
