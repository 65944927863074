/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { startTransition, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Checkbox } from '@mui/material';
import images from '../../assets/images';
import UnauthorizedAccess from '../../components/UnauthorizedAccess';
import EditBuildcardNameDialog from '../../components/dialog/EditBuildcardNameDialog';
import DropdownIcon from '../../components/utils/DropdownIcon';
import instance from '../../shared/interceptor';
import {
  addTag,
  getAllSpaceGoals,
  getAllTags,
  handleDelete,
} from '../../shared/services/common.service';
import BuildcardComp from '../../shared/shared-comp/BuildcardComp';
import GoalsTags from '../../shared/shared-comp/GoalsTags';
import LoaderComp from '../../shared/shared-comp/LoaderComp';
import OverflowTooltip from '../../shared/shared-comp/OverflowTooltip';
import { currentSpaceState } from '../../shared/store/slices/companySlice';
import {
  companyState,
  permissionState,
  userPermissionState,
  userState,
} from '../../shared/store/slices/userSlice';
import { SPACES } from '../../shared/util/constant';
import {
  debounce,
  formatDate,
  getBcState,
  getCardLable,
  globalError,
  groupBy,
  isAllowedTo,
  isPrivateSpaceOwner,
  isUserCioOrSpaceMember,
  removeByAttr,
  snakeCaseToSentence,
  sortProject,
} from '../../shared/util/util';
import FilteredSpaceOverview from './FilteredSpaceOverview';
import TagResultSection from './TagResultSection';
import './project.scss';
import './spaces-overview.scss';

export default function SpaceOverview() {
  const dispatch = useDispatch();
  const companySel = useSelector(companyState);
  const userSel = useSelector(userState);
  const currentSpace = useSelector(currentSpaceState);
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);
  const navigate = useNavigate();
  const params = useParams();

  const [buildcardList, setBuildcardList] = useState(null);
  const [searchBuildcardList, setSearchBuildcardList] = useState(null);
  const [buildcardSearchText, setBuildcardSearchText] = useState('');
  const [tagList, setTagList] = useState(null);
  const [showSearchTags, setShowSearchTags] = useState(null);
  const [overviewTabValue, setOverviewTabValue] = useState('tabGridView');
  const [spaceGoals, setSpaceGoals] = useState([]);
  const [searchSpaceGoals, setSearchSpaceGoals] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [showEmptyState, setShowEmptyState] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const openFilterMenu = Boolean(anchorEl2);
  const [groupOfBCList, setGroupOfBCList] = useState(null);
  const [filteredValue, setFilteredValue] = useState(null);
  const [editingListCard, setEditingListCard] = useState(null);
  const [anchorEpdf, setAnchorEpdf] = useState(null);
  const open = Boolean(anchorEpdf);

  const handleClickFilter = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const entity = { id: currentSpace?.id, type: 'Space' };
  const isAllowedToUpdateEntityTag = isAllowedTo(
    'update',
    'entity_tag',
    permissions,
    entity,
    userPermissions
  );

  const moreTagsDropdownClick = (id, event, elem) => {
    event.preventDefault();
    event.stopPropagation();
    elem({ [id]: event.currentTarget });
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (companySel?.id) {
      getListOfBuildcards(signal);
    }

    return () => {
      controller.abort();
      setShowEmptyState(false);
    };
  }, [params?.id, companySel?.id]);

  useEffect(() => {
    (async () => {
      if (
        isAllowedTo(
          'read',
          'space_goal',
          permissions,
          entity,
          userPermissions
        ) &&
        companySel?.id
      ) {
        let res = await getAllSpaceGoals(dispatch, companySel, params);
        setSpaceGoals(res?.['space_goals']);
        setSearchSpaceGoals(res?.['space_goals']);
      }
    })();
  }, []);

  const updateBuildCardAttributes = (cardId, newAttributes) => {
    setBuildcardList((prevCards) =>
      prevCards.map((card) =>
        card.id === cardId ? { ...card, ...newAttributes } : card
      )
    );

    setSearchBuildcardList((prevCards) =>
      prevCards.map((card) =>
        card.id === cardId ? { ...card, ...newAttributes } : card
      )
    );
  };

  const getListOfBuildcards = async (signal, query = '') => {
    if (params?.id) {
      try {
        setShowLoading(true);
        let url = `${SPACES.replace(':id', companySel?.id)}/${params?.id}/list_buildcards`;
        if (query) {
          url += `?q=${query}`;
        }
        let res = await instance.get(url, { signal });
        if (res?.['build_cards']?.length > 0) {
          setBuildcardList(
            sortProject({ target: { value: 'A-Z' } }, res?.['build_cards'])
          );
          setGroupOfBCList(groupBy(res?.['build_cards'], 'state', true));
          setSearchBuildcardList(res?.['build_cards']);
        }
        res?.['build_cards'] && !res?.['build_cards']?.length
          ? setShowEmptyState(true)
          : setShowEmptyState(false);
      } catch (e) {
        globalError(dispatch, e);
      } finally {
        setShowLoading(false);
      }
    }
  };

  const searchBuildcards = (e) => {
    let val = e?.target?.value;
    setBuildcardSearchText(val);
    if (val) {
      setBuildcardList(
        searchBuildcardList.filter((item) =>
          item?.alias_name?.toLowerCase()?.includes(val?.toLowerCase())
        )
      );
    } else {
      setBuildcardList(searchBuildcardList);
    }
  };

  const redirect = (card) => {
    if (
      card?.state == 'ongoing' ||
      card?.state == 'running' ||
      card?.state == 'completed' ||
      card?.state == 'ready_for_kickoff'
    ) {
      window.open(card?.home_url, '_self');
    } else if (
      (!card?.state ||
        (card?.state != 'ongoing' && card?.state != 'completed')) &&
      (card?.state == 'draft' || card?.state == 'pending_approval')
    ) {
      startTransition(() => {
        navigate(
          `/company/spaces/${params?.id}/playground/${card.enterprise_id}`
        );
      });
    }
  };

  const clearInput = () => {
    setBuildcardSearchText('');
    setBuildcardList(searchBuildcardList);
  };

  const emptyState = () => {
    return (
      <div className="no-data-wrapper no-buildcard" aria-label="empty-state">
        <em className="thumb">
          <img src={images.emptyStateIllustration} alt="Spaces Flag" />
        </em>
        <h4 aria-label="no-space">No Buildcard yet</h4>
        <p>Your space does not have any ongoing Buildcards yet.</p>
        {isAllowedTo(
          'update',
          'buildcard',
          permissions,
          entity,
          userPermissions
        ) && (
          <Button
            variant="contained"
            className="btn-add-goals grey-btn"
            onClick={() =>
              navigate(`/company/spaces/${params?.id}/space-playground`)
            }
          >
            Start working on an idea
          </Button>
        )}
      </div>
    );
  };

  const noResult = () => {
    return (
      <div className="no-data-wrapper">
        <em className="thumb">
          <img src={images.emptystateNosearch} alt="Spaces Flag" />
        </em>
        <h4 aria-label="no-space">No Buildcard</h4>
        <p>No Buildcard found by this name in this space.</p>
      </div>
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
    setTagList(null);
  };

  const tagChangeHandler = debounce((e, card) => {
    if (e.key === 'Enter') {
      addTags(e.target.value, card);
      handleClose();
    } else {
      getTagList(e.target['value']);
    }
  });

  const getTagList = async (query?) => {
    let tagsRes = await getAllTags(companySel, dispatch, query);
    setTagList(tagsRes['tags']);
  };

  const mutateBuildcardList = (response, toAdd) => {
    return buildcardList.map((bc) => {
      if (bc?.enterprise_id == response?.['entity_tag']?.taggable_id) {
        if (toAdd) {
          return {
            ...bc,
            entity_tags: [
              ...bc?.entity_tags,
              {
                id: response?.['entity_tag']?.['id'],
                name: response?.['entity_tag']?.['name'],
              },
            ],
          };
        } else {
          return {
            ...bc,
            entity_tags: bc?.entity_tags?.filter(
              (item) => item?.id !== response?.['entity_tag']?.id
            ),
          };
        }
      } else {
        return bc;
      }
    });
  };

  const addTags = async (tagName, card) => {
    let response = await addTag(companySel, dispatch, tagName, card);
    let filteredProject = mutateBuildcardList(response, true);
    setBuildcardList(filteredProject);
    // handleClose();
  };

  const deleteTag = async (tagId) => {
    let response = await handleDelete(dispatch, companySel, tagId);
    let filteredProject = mutateBuildcardList(response, false);
    setBuildcardList(filteredProject);
  };

  const tagInput = (card) => {
    return (
      <div>
        <FormControl variant="filled" onKeyDown={(e) => e.stopPropagation()}>
          <FilledInput
            type="text"
            className="xs-input search-tag-input"
            placeholder="Search or create new"
            autoFocus
            startAdornment={<InputAdornment position="start"></InputAdornment>}
            onKeyDown={(e) => {
              (e as any)?.stopPropagation();
              tagChangeHandler(e, card);
            }}
            onClick={(e) => (e as any)?.stopPropagation()}
            inputProps={{ 'data-testid': 'tag-input' }}
          />
        </FormControl>
      </div>
    );
  };

  const searchSpaceGoalList = (event) => {
    event?.stopPropagation();
    let val = event?.target?.value;
    if (val) {
      setSpaceGoals(
        searchSpaceGoals.filter((item) =>
          item?.name?.toLowerCase()?.includes(val?.toLowerCase())
        )
      );
    } else {
      setSpaceGoals(searchSpaceGoals);
    }
  };

  const updateGoals = (goal, card, remove = false) => {
    let mappedList = buildcardList?.map((bc) => {
      if (bc?.enterprise_id == card?.enterprise_id) {
        if (remove) {
          return {
            ...bc,
            space_goals: removeByAttr([...bc?.space_goals], 'id', goal?.id),
          };
        }
        return {
          ...bc,
          space_goals: [
            ...bc?.space_goals,
            { id: goal?.id, name: goal?.['name'] },
          ],
        };
      } else {
        return bc;
      }
    });
    setBuildcardList(mappedList);
  };

  const sortBuildcardList = (e) => {
    setBuildcardList(sortProject(e, buildcardList));
  };

  const canDelete = () =>
    isAllowedTo(
      'destroy',
      'entity_tag',
      permissions,
      { id: currentSpace?.id, type: 'Space' },
      userPermissions
    );

  const handleClosepdf = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEpdf(null);
  };

  const handleEditBuildcardNameDialogClose = () => {
    setEditingListCard(null);
  };

  const openRenameBcDialog = (e, card) => {
    e?.stopPropagation();
    setEditingListCard(card);
    setAnchorEpdf(null);
  };

  const handleClick = (event, idx) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEpdf((prev) => ({
      ...prev,
      [idx]: event.currentTarget as HTMLElement,
    }));
  };

  const downloadBcPdf = (ev, card) => {
    ev.stopPropagation();
    window.open(card?.build_card_pdf_url, '_blank', 'noopener,noreferrer');
    setAnchorEpdf(null);
  };

  const getCheckedState = (e) => {
    setFilteredValue((prev) => ({
      ...prev,
      [e?.target?.value]: e?.target?.checked,
    }));
  };

  const getFilteredCond = () => {
    return (
      filteredValue &&
      Object.keys(filteredValue).filter(function (key) {
        return filteredValue[key];
      }).length > 0
    );
  };

  const getFilteredBuildcardCount = () => {
    if (filteredValue) {
      return Object.keys(filteredValue)
        .filter(function (key) {
          return filteredValue[key];
        })
        .reduce((acc, value) => {
          return acc + groupOfBCList[value]?.length;
        }, 0);
    }
  };

  const updateBuildCardName = (cardId, newName) => {
    setBuildcardList((prevCards) =>
      prevCards.map((card) =>
        card.id === cardId
          ? { ...card, alias_name: newName, name: newName }
          : card
      )
    );
    setSearchBuildcardList((prevCards) =>
      prevCards.map((card) =>
        card.id === cardId
          ? { ...card, alias_name: newName, name: newName }
          : card
      )
    );
  };

  //=============================================================
  return isAllowedTo('read', 'space', permissions, entity, userPermissions) &&
    isPrivateSpaceOwner(currentSpace, userSel) ? (
    <>
      <div className="space-overview-page" aria-label="space-overview">
        <div className="page-container pb-0">
          <section className="overview-inner-section spaces-overview-outer">
            <div className="page-content">
              <div className="page-filters-wrapper">
                <div className="left-filter btn-wrapper">
                  <span aria-label="count" className="count filter-title">
                    {getFilteredCond() ? (
                      getFilteredBuildcardCount()
                    ) : !showLoading ? (
                      (buildcardList?.length ?? 0)
                    ) : (
                      <LoaderComp
                        borderLessStyle={{ display: 'inline-flex' }}
                      />
                    )}
                    &nbsp;Buildcards
                  </span>
                </div>
                <div className="right-filter">
                  <div className="form-group sort-by">
                    <span className="dropdown-text">Sort by</span>
                    <Select
                      IconComponent={DropdownIcon}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'right',
                        },
                      }}
                      className="select-icon borderless-dropdown sm-height sm-right no-label"
                      defaultValue={'A-Z'}
                      onChange={sortBuildcardList}
                    >
                      <MenuItem className="sorting-menu-item" value="A-Z">
                        A-Z
                      </MenuItem>
                      <MenuItem className="sorting-menu-item" value="Z-A">
                        Z-A
                      </MenuItem>
                      <MenuItem className="sorting-menu-item" value="Latest">
                        Latest
                      </MenuItem>
                      <MenuItem className="sorting-menu-item" value="Oldest">
                        Oldest
                      </MenuItem>
                    </Select>
                  </div>
                  <Tooltip placement="top" title="Filter by">
                    <Button
                      data-testid=""
                      variant="contained"
                      className="btn-filter-by"
                      onClick={handleClickFilter}
                    >
                      <em className="be be-filter-by"></em>
                      <span className={getFilteredCond() ? 'count' : ''}>
                        {getFilteredCond() &&
                          Object.keys(filteredValue).filter(function (key) {
                            return filteredValue[key];
                          }).length}
                      </span>
                    </Button>
                  </Tooltip>
                  <Menu
                    className="filter-by-dropdown-menu"
                    id=""
                    anchorEl={anchorEl2}
                    open={openFilterMenu}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem
                      className="toggle-menu-item-row"
                      aria-label="download-btn"
                      //onClick={handleClose}
                    >
                      <div className="head">
                        <div className="left">
                          <span className="title">Filter by</span>
                        </div>
                        <div className="right">
                          <span
                            className="reset-link"
                            onClick={() => setFilteredValue(null)}
                          >
                            Reset
                          </span>
                          <span
                            className="modal-close be-close"
                            onClick={handleClose}
                          ></span>
                        </div>
                      </div>
                      <div className="body-content">
                        <div className="inner-wrap">
                          <div className="title">
                            <em className="be be-buildcard"></em> Buildcard
                            status
                          </div>
                          <ul>
                            {groupOfBCList &&
                              Object?.keys(groupOfBCList)?.length > 0 &&
                              Object?.keys(groupOfBCList)?.map((item, idx) => {
                                return (
                                  <li key={idx + 1}>
                                    <Checkbox
                                      className="square-checkbox"
                                      checked={
                                        !!filteredValue?.[
                                          groupOfBCList?.[item]?.[0]?.state
                                        ]
                                      }
                                      icon={
                                        <img
                                          src={images.checkboxUnchecked}
                                          alt=""
                                        />
                                      }
                                      checkedIcon={
                                        <img
                                          src={images.checkboxChecked}
                                          alt=""
                                        />
                                      }
                                      onChange={getCheckedState}
                                      value={groupOfBCList?.[item]?.[0]?.state}
                                    />
                                    <em
                                      className="dot sm"
                                      style={{
                                        background: `${getBcState(groupOfBCList?.[item]?.[0]?.state)}`,
                                      }}
                                    ></em>{' '}
                                    {snakeCaseToSentence(
                                      getCardLable(
                                        groupOfBCList?.[item]?.[0]?.state
                                      )
                                    )}
                                    <span className="count">
                                      &nbsp;({groupOfBCList?.[item]?.length})
                                    </span>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </MenuItem>
                  </Menu>

                  <FormControl variant="filled" className="search-input">
                    {/* @ts-ignore */}
                    <FilledInput
                      inputProps={{ 'data-testid': 'searchProjects' }}
                      type="text"
                      className="xs-input search-input-wrap"
                      placeholder="Search Buildcard"
                      startAdornment={
                        <InputAdornment position="start">
                          <em className="be-search"></em>
                        </InputAdornment>
                      }
                      value={buildcardSearchText}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          className="search-icon-wrap"
                        >
                          {buildcardSearchText && (
                            <img
                              src={images.iconCrossCircle}
                              alt=""
                              onClick={clearInput}
                            />
                          )}
                        </InputAdornment>
                      }
                      onChange={searchBuildcards}
                    />
                  </FormControl>
                  <div className="button-grouping">
                    <Tooltip
                      placement="bottom"
                      title="Grid View"
                      style={{ width: 'auto' }}
                    >
                      <Button
                        data-testid="tab-grid-view"
                        variant="contained"
                        className={`inner-btn ${overviewTabValue === 'tabGridView' ? 'active' : ''}`}
                        onClick={() => setOverviewTabValue('tabGridView')}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.5 3.99805C2.5 3.16962 3.17157 2.49805 4 2.49805H7C7.82843 2.49805 8.5 3.16962 8.5 3.99805V6.99805C8.5 7.82647 7.82843 8.49805 7 8.49805H4C3.17157 8.49805 2.5 7.82647 2.5 6.99805V3.99805Z"
                            className="stroke"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.5 12.998C2.5 12.1696 3.17157 11.498 4 11.498H7C7.82843 11.498 8.5 12.1696 8.5 12.998V15.998C8.5 16.8265 7.82843 17.498 7 17.498H4C3.17157 17.498 2.5 16.8265 2.5 15.998V12.998Z"
                            className="stroke"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.5 3.99805C11.5 3.16962 12.1716 2.49805 13 2.49805H16C16.8284 2.49805 17.5 3.16962 17.5 3.99805V6.99805C17.5 7.82647 16.8284 8.49805 16 8.49805H13C12.1716 8.49805 11.5 7.82647 11.5 6.99805V3.99805Z"
                            className="stroke"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.5 12.998C11.5 12.1696 12.1716 11.498 13 11.498H16C16.8284 11.498 17.5 12.1696 17.5 12.998V15.998C17.5 16.8265 16.8284 17.498 16 17.498H13C12.1716 17.498 11.5 16.8265 11.5 15.998V12.998Z"
                            className="stroke"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                    </Tooltip>
                    <Tooltip
                      placement="bottom"
                      title="List View"
                      style={{ width: 'auto' }}
                    >
                      <Button
                        data-testid="tab-table-view"
                        variant="contained"
                        className={`inner-btn ${overviewTabValue === 'tabListView' ? 'active' : ''}`}
                        onClick={() => setOverviewTabValue('tabListView')}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3 4.49805C3 3.66962 3.67157 2.99805 4.5 2.99805H15.5C16.3284 2.99805 17 3.66962 17 4.49805V15.498C17 16.3265 16.3284 16.998 15.5 16.998H4.5C3.67157 16.998 3 16.3265 3 15.498V4.49805Z"
                            className="stroke"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3 6.99805H17"
                            className="stroke"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 16.998V6.99805"
                            className="stroke"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3 11.998H17"
                            className="stroke"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>

              {getFilteredCond() && overviewTabValue === 'tabGridView' && (
                <FilteredSpaceOverview
                  {...{
                    buildcardList,
                    entity,
                    searchSpaceGoalList,
                    updateGoals,
                    setShowSearchTags,
                    deleteTag,
                    addTags,
                    tagChangeHandler,
                    updateBuildCardName,
                    spaceGoals,
                    tagList,
                    filteredValue,
                  }}
                />
              )}

              {!showLoading &&
                showEmptyState &&
                !buildcardSearchText &&
                emptyState()}
              {buildcardSearchText && !buildcardList?.length && noResult()}
              {/* Accordion wrapper: list and grid view */}
              {showLoading && <LoaderComp maxHeight="32rem" />}
              <div className="accordion-table-holder tab-scroll-spacing">
                {/* Table content start */}
                {buildcardList?.length > 0 && !showLoading && (
                  <div className="table-wrapper table-responsive accordion-head-table-wrap">
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={5}>
                              <div
                                className={`accordion-wrapper ${overviewTabValue == 'tabGridView' ? 'overflow-hidden' : ''}`}
                              >
                                {/* List table wrapper */}
                                {overviewTabValue == 'tabListView' && (
                                  <div
                                    data-testid="bc-table-view"
                                    className="table-wrapper  expended-list-table-wrap "
                                  >
                                    <TableContainer>
                                      <Table>
                                        <TableHead>
                                          <TableRow>
                                            <TableCell width="26%">
                                              Buildcard Name
                                            </TableCell>
                                            <TableCell width="9%">
                                              Tags
                                            </TableCell>
                                            <TableCell width="8%">
                                              Goals
                                            </TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Delivery</TableCell>
                                            <TableCell className="text-right">
                                              Features
                                            </TableCell>
                                            <TableCell className="text-right">
                                              Platforms
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Progress
                                            </TableCell>
                                            <TableCell className="text-right">
                                              Credits
                                            </TableCell>
                                            <TableCell></TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody className="table-body">
                                          {buildcardList
                                            ?.filter((item) => {
                                              if (getFilteredCond()) {
                                                return Object.keys(
                                                  filteredValue
                                                )
                                                  ?.filter(function (key) {
                                                    return filteredValue[key];
                                                  })
                                                  ?.includes(item?.state);
                                              }
                                              return item;
                                            })
                                            ?.map((card, idx) => (
                                              <TableRow
                                                key={card?.id}
                                                onClick={() => redirect(card)}
                                              >
                                                <TableCell width="26%">
                                                  <span
                                                    className="card-title"
                                                    data-testid="navigate-heading"
                                                    style={{
                                                      cursor: 'pointer',
                                                      display: 'flex',
                                                    }}
                                                  >
                                                    <em className="build-icon">
                                                      {' '}
                                                      <img
                                                        src={
                                                          images.iconBuildcardXS
                                                        }
                                                        alt=""
                                                      />
                                                    </em>
                                                    <OverflowTooltip
                                                      text={
                                                        card?.alias_name ??
                                                        card?.name
                                                      }
                                                      tooltip={
                                                        card?.alias_name ??
                                                        card?.name
                                                      }
                                                    />
                                                  </span>
                                                </TableCell>
                                                <TableCell>
                                                  {/* will be hidden once clicked and added tags */}
                                                  {!card?.entity_tags
                                                    ?.length && (
                                                    <div
                                                      data-testid="open-menu-btn"
                                                      className="card-tags-wrap list-view"
                                                    >
                                                      <Tooltip
                                                        placement="top-end"
                                                        arrow
                                                        title={
                                                          isAllowedToUpdateEntityTag
                                                            ? 'Add tag'
                                                            : 'Cannot add tag'
                                                        }
                                                        classes={{
                                                          popper:
                                                            'info-tooltip',
                                                        }}
                                                      >
                                                        <Button
                                                          variant="contained"
                                                          className="xs-btn is-icon-btn purple-btn has-border"
                                                          onClick={(e) => {
                                                            (
                                                              e as any
                                                            )?.stopPropagation();
                                                            moreTagsDropdownClick(
                                                              card?.id,
                                                              e,
                                                              setAnchorEl
                                                            );
                                                          }}
                                                        >
                                                          <em className="icon be-tag-pin"></em>
                                                        </Button>
                                                      </Tooltip>
                                                    </div>
                                                  )}

                                                  {/* wll be shown once tags added */}
                                                  <div className="card-tags-wrap list-view">
                                                    {card?.entity_tags?.length >
                                                      0 && (
                                                      <Button
                                                        data-testid="open-menu-btn"
                                                        variant="contained"
                                                        className="xs-btn is-icon-btn purple-btn has-border whitebg"
                                                        onClick={(e) => {
                                                          (
                                                            e as any
                                                          )?.stopPropagation();
                                                          moreTagsDropdownClick(
                                                            card?.id,
                                                            e,
                                                            setAnchorEl
                                                          );
                                                        }}
                                                      >
                                                        +
                                                        {
                                                          card?.entity_tags
                                                            ?.length
                                                        }
                                                      </Button>
                                                    )}
                                                    <Menu
                                                      aria-label="tags-list-menu"
                                                      className="taglist-menu cards"
                                                      disableRestoreFocus
                                                      anchorEl={
                                                        anchorEl?.[card?.id]
                                                      }
                                                      open={Boolean(
                                                        anchorEl?.[card?.id]
                                                      )}
                                                      onClose={(e) => {
                                                        (
                                                          e as any
                                                        )?.stopPropagation();
                                                        handleClose();
                                                      }}
                                                      anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                      }}
                                                      transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                      }}
                                                    >
                                                      {card?.entity_tags
                                                        ?.length > 0 ? (
                                                        <div className="selected-tags">
                                                          {card?.entity_tags?.map(
                                                            (tag) => (
                                                              <Stack
                                                                aria-label="bc-entity-tag"
                                                                key={tag?.id}
                                                                direction="row"
                                                                className="purple-chips"
                                                                spacing={1}
                                                              >
                                                                <Chip
                                                                  label={
                                                                    <OverflowTooltip
                                                                      text={
                                                                        tag?.name
                                                                      }
                                                                      tooltip={
                                                                        tag?.name
                                                                      }
                                                                      placement={
                                                                        'top'
                                                                      }
                                                                    />
                                                                  }
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    (
                                                                      e as any
                                                                    )?.stopPropagation();
                                                                    setShowSearchTags(
                                                                      tag
                                                                    );
                                                                    handleClose();
                                                                  }}
                                                                  onDelete={
                                                                    canDelete()
                                                                      ? (e) => {
                                                                          (
                                                                            e as any
                                                                          )?.stopPropagation();
                                                                          deleteTag(
                                                                            tag?.id
                                                                          );
                                                                          // handleClose();
                                                                        }
                                                                      : null
                                                                  }
                                                                  deleteIcon={
                                                                    <span
                                                                      data-testid="delete-tag"
                                                                      className="icon be-close-dark sm-close"
                                                                    ></span>
                                                                  }
                                                                />
                                                              </Stack>
                                                            )
                                                          )}
                                                        </div>
                                                      ) : (
                                                        <div className="empty-tags-wrapper">
                                                          <p className="empty-text">
                                                            No tag added yet
                                                          </p>
                                                        </div>
                                                      )}
                                                      {isAllowedToUpdateEntityTag &&
                                                        tagInput(card)}
                                                      <div
                                                        style={{
                                                          maxHeight: '260px',
                                                        }}
                                                      >
                                                        {tagList
                                                          ?.filter(
                                                            (tag) =>
                                                              !card?.entity_tags
                                                                .map(
                                                                  (entity) =>
                                                                    entity?.name
                                                                )
                                                                ?.includes(
                                                                  tag?.name
                                                                )
                                                          )
                                                          ?.map((item) => (
                                                            <div
                                                              key={item?.id}
                                                              className="tag-row"
                                                            >
                                                              <Stack
                                                                direction="row"
                                                                className="purple-chips"
                                                                spacing={1}
                                                              >
                                                                <Chip
                                                                  label={
                                                                    <OverflowTooltip
                                                                      text={
                                                                        item?.name
                                                                      }
                                                                      tooltip={
                                                                        item?.name
                                                                      }
                                                                      placement={
                                                                        'top'
                                                                      }
                                                                    />
                                                                  }
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    (
                                                                      e as any
                                                                    )?.stopPropagation();
                                                                    addTags(
                                                                      item?.name,
                                                                      card
                                                                    );
                                                                  }}
                                                                />
                                                              </Stack>
                                                            </div>
                                                          ))}
                                                      </div>
                                                    </Menu>
                                                  </div>
                                                </TableCell>
                                                <TableCell className="spacegoals">
                                                  <GoalsTags
                                                    {...{
                                                      card,
                                                      idx,
                                                      spaceGoals,
                                                      searchSpaceGoalList,
                                                      updateGoals,
                                                      goalNumber: false,
                                                    }}
                                                  />
                                                </TableCell>
                                                <TableCell>
                                                  <Stack
                                                    direction="row"
                                                    spacing={1}
                                                  >
                                                    <Chip
                                                      label={getCardLable(
                                                        card?.state
                                                      )}
                                                      className={getBcState(
                                                        card?.state
                                                      )}
                                                    />
                                                  </Stack>
                                                </TableCell>
                                                <TableCell>
                                                  {card?.delivery_date
                                                    ? formatDate(
                                                        card?.delivery_date
                                                      )
                                                    : '-'}
                                                </TableCell>
                                                <TableCell className="text-right">
                                                  {card?.number_of_features}
                                                </TableCell>
                                                <TableCell className="platform text-right">
                                                  <Tooltip
                                                    className="progress-tooltip-popover"
                                                    placement="bottom"
                                                    enterTouchDelay={0}
                                                    title={
                                                      <ul className="platform-name">
                                                        {card?.platforms?.map(
                                                          (platform) => (
                                                            <li key={platform}>
                                                              <a>{platform}</a>
                                                            </li>
                                                          )
                                                        )}
                                                      </ul>
                                                    }
                                                  >
                                                    <>
                                                      {
                                                        card?.number_of_platforms
                                                      }
                                                    </>
                                                  </Tooltip>
                                                </TableCell>
                                                <TableCell>
                                                  <div>
                                                    {![
                                                      'draft',
                                                      'pending_approval',
                                                      'ready_for_kickoff',
                                                    ].includes(card?.state) ? (
                                                      <div className="progress-circle">
                                                        <Tooltip
                                                          className="progress-tooltip-popover"
                                                          placement="bottom"
                                                          enterTouchDelay={0}
                                                          title={
                                                            <div className="credits">
                                                              <span>
                                                                {card?.completion ??
                                                                  0}
                                                                %
                                                              </span>
                                                            </div>
                                                          }
                                                        >
                                                          <div className="progress-with-bg">
                                                            <CircularProgress
                                                              size={24}
                                                              variant="determinate"
                                                              thickness={4}
                                                              className="bg-progress"
                                                              value={100}
                                                            />
                                                            <CircularProgress
                                                              size={24}
                                                              variant="determinate"
                                                              thickness={4}
                                                              value={
                                                                card?.completion ??
                                                                0
                                                              }
                                                            />
                                                          </div>
                                                        </Tooltip>
                                                      </div>
                                                    ) : (
                                                      <div className="text-center">
                                                        -
                                                      </div>
                                                    )}
                                                  </div>
                                                </TableCell>
                                                <TableCell className="text-right">
                                                  {card?.final_cost ?? 0}
                                                </TableCell>
                                                <TableCell>
                                                  <div
                                                    className="xs-btn  three-dots-btn icon-btn-wrap"
                                                    key={idx}
                                                    data-testid="goal-tags-comp"
                                                    onClick={(e) =>
                                                      e?.stopPropagation()
                                                    }
                                                  >
                                                    <em
                                                      onClick={(e) => {
                                                        {
                                                          (
                                                            e as any
                                                          )?.stopPropagation();
                                                          handleClick(e, idx);
                                                        }
                                                      }}
                                                      className="icon be-vertical-dots"
                                                      style={{
                                                        fontSize:
                                                          '2rem; display:flex',
                                                      }}
                                                    ></em>
                                                  </div>
                                                  <Menu
                                                    className="three-dot-menu-paper"
                                                    id="basic-menu"
                                                    anchorEl={anchorEpdf?.[idx]}
                                                    open={Boolean(
                                                      anchorEpdf?.[idx]
                                                    )}
                                                    onClose={handleClosepdf}
                                                    MenuListProps={{
                                                      'aria-labelledby':
                                                        'basic-button',
                                                    }}
                                                    anchorOrigin={{
                                                      vertical: 'bottom',
                                                      horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                      vertical: 'top',
                                                      horizontal: 'right',
                                                    }}
                                                  >
                                                    {[
                                                      entity,
                                                      {
                                                        id: card?.enterprise_id,
                                                        type: 'BuildCard',
                                                      },
                                                    ].some((item) =>
                                                      isAllowedTo(
                                                        'modify',
                                                        'buildcard',
                                                        permissions,
                                                        item,
                                                        userPermissions
                                                      )
                                                    ) && (
                                                      <MenuItem
                                                        className="toggle-menu-item-row"
                                                        onClick={(e) =>
                                                          openRenameBcDialog(
                                                            e,
                                                            card
                                                          )
                                                        }
                                                      >
                                                        <em className="icon be-edit"></em>{' '}
                                                        Rename
                                                      </MenuItem>
                                                    )}

                                                    {card?.build_card_pdf_url &&
                                                      isUserCioOrSpaceMember(
                                                        userSel,
                                                        currentSpace
                                                      ) && (
                                                        <MenuItem
                                                          className="toggle-menu-item-row"
                                                          onClick={(ev) =>
                                                            downloadBcPdf(
                                                              ev,
                                                              card
                                                            )
                                                          }
                                                        >
                                                          <em className="icon be-download"></em>{' '}
                                                          Buildcard summary
                                                        </MenuItem>
                                                      )}
                                                    <MenuItem
                                                      className="toggle-menu-item-row"
                                                      aria-label="archive-btn"
                                                      onClick={(ev) =>
                                                        ev.stopPropagation()
                                                      }
                                                    >
                                                      <em className="icon be-archive"></em>{' '}
                                                      Archive
                                                    </MenuItem>
                                                  </Menu>
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </div>
                                )}
                                {/* List table wrapper end */}

                                {/* Grid view cards wrapper */}
                                {!getFilteredCond() &&
                                  overviewTabValue == 'tabGridView' && (
                                    <div className="grid-view-cards-wrapper">
                                      <div className="data-container">
                                        <div className="row">
                                          {buildcardList?.map((card, idx) => (
                                            <div
                                              key={card?.id}
                                              className="col-xs-12 col-sm-6 col-lg-6 col-xl-4"
                                            >
                                              <BuildcardComp
                                                {...{
                                                  card,
                                                  entity,
                                                  idx,
                                                  spaceGoals,
                                                  searchSpaceGoalList,
                                                  updateGoals,
                                                  setShowSearchTags,
                                                  deleteTag,
                                                  tagList,
                                                  addTags,
                                                  tagChangeHandler,
                                                  shouldNavigate: true,
                                                  showThreeDots: true,
                                                }}
                                              />
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                {/* Grid view cards wrapper end */}
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
                {editingListCard && (
                  <EditBuildcardNameDialog
                    card={editingListCard}
                    openRenameBc={Boolean(editingListCard)}
                    handleClose={handleEditBuildcardNameDialogClose}
                    updateBuildCardAttributes={updateBuildCardAttributes}
                  />
                )}
                {/* Table content end */}
              </div>
            </div>

            {/* <Summary /> */}
          </section>
        </div>
      </div>

      {/* Tag result section */}
      {showSearchTags && (
        <TagResultSection {...{ showSearchTags, setShowSearchTags }} />
      )}
      {/* Tag result section end */}
    </>
  ) : (
    <UnauthorizedAccess />
  );
}
