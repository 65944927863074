import React, { useEffect, useState } from 'react';
import RightPageHeader from '../../../shared/shared-comp/RightPageHeader';
import {
  Button,
  FormControl,
  FilledInput,
  InputAdornment,
  MenuItem,
  Select,
  Menu,
  Pagination,
} from '@mui/material';
import images from '../../../assets/images';
import { Link } from 'react-router-dom';
import '../../company/catalog/catalog.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCatalogDetails,
  fetchCompanyCatalogs,
} from '../../../shared/services/common.service';
import { companyState } from '../../../shared/store/slices/userSlice';
import LoaderComp from '../../../shared/shared-comp/LoaderComp';
import CatalogCard from './CatalogCard';

function Catalog() {
  const [catalogs, setCatalogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEpdf, setAnchorEpdf] = useState(null);
  const [filteredCatalogs, setFilteredCatalogs] = useState([]);
  const [catalogsLoading, setCatalogsLoading] = useState(false);

  const itemsPerPage = 4;
  const dispatch = useDispatch();
  const companySel = useSelector(companyState);

  const totalPages = Math.ceil(filteredCatalogs.length / itemsPerPage);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getCatalogs();
  }, [companySel?.id]);

  const getCatalogs = async () => {
    try {
      setCatalogsLoading(true);
      if (companySel?.id) {
        const response = await fetchCompanyCatalogs(companySel.id, dispatch);
        if (response?.['catalogs']) {
          const sortedCatalogs = sortCatalogs(
            response?.['catalogs'],
            'Created on'
          );
          setCatalogs(sortedCatalogs);
          setFilteredCatalogs(sortedCatalogs);
        }
      }
    } catch (error) {
      console.log('Error', error);
    } finally {
      setCatalogsLoading(false);
    }
  };

  const searchCatalogs = (e) => {
    e?.stopPropagation();
    setFilteredCatalogs(
      catalogs.filter((catalog) =>
        catalog.name.toLowerCase().includes(e?.target?.value)
      )
    );
    setCurrentPage(1);
  };

  const sortCatalogs = (catalogsList, sortBy: string) => {
    switch (sortBy) {
      case 'A-Z':
        return catalogsList.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );

      case 'Z-A':
        return catalogsList.sort((a, b) =>
          b.name.toLowerCase().localeCompare(a.name.toLowerCase())
        );

      case 'Created on':
        return catalogsList.sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB.getTime() - dateA.getTime(); // Most recent first
        });

      default:
        return catalogsList;
    }
  };

  const sortAndSetCatalogs = (sortBy: string) => {
    setFilteredCatalogs((prev) => sortCatalogs([...prev], sortBy));
  };

  const getCurrentPageItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredCatalogs.slice(startIndex, endIndex);
  };

  return (
    <div
      className="page-container top-space catalog-page"
      data-testid="catalog-page"
    >
      <div className="main-page-header ">
        <div className="page-breacrumb">
          <div className="breadcrumb-wrapper">
            <ul className="breadcrumb-links">
              <li>
                <Link to={'/company/company-settings'}>Company</Link>
              </li>
              <li>Catalogue</li>
            </ul>
          </div>
          <RightPageHeader />
        </div>
        <div className="page-title d-flex justify-content-between">
          <h2>Catalogue</h2>
          <div className="page-filters-wrapper">
            <div className="right-filter">
              <div className="form-group sort-by">
                <span className="dropdown-text">Sort by</span>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                  }}
                  className="borderless-dropdown sm-height no-label"
                  defaultValue={'Created on'}
                  onChange={(e) => sortAndSetCatalogs(e.target.value)}
                >
                  <MenuItem className="sorting-menu-item" value="A-Z">
                    A-Z
                  </MenuItem>
                  <MenuItem className="sorting-menu-item" value="Z-A">
                    Z-A
                  </MenuItem>
                  <MenuItem className="sorting-menu-item" value="Created on">
                    Created on
                  </MenuItem>
                </Select>
              </div>
              <FormControl variant="filled" className="search-input">
                {/* @ts-ignore */}
                <FilledInput
                  inputProps={{ 'data-testid': 'searchProjects' }}
                  type="text"
                  className="xs-input search-input-wrap"
                  placeholder="Search template"
                  startAdornment={
                    <InputAdornment position="start">
                      <em className="be-search"></em>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end" className="search-icon-wrap">
                      {/* {buildcardSearchText && (
                          <img
                            src={images.iconCrossCircle}
                            alt=""
                            onClick={clearInput}
                          />
                        )} */}
                    </InputAdornment>
                  }
                  onChange={searchCatalogs}
                />
              </FormControl>
            </div>
          </div>
        </div>
      </div>

      {catalogsLoading && <LoaderComp />}
      {!catalogsLoading && (
        <>
          <ul className="filterList">
            {getCurrentPageItems().map((catalog: any) => (
              <li key={catalog?.id}>
                <CatalogCard
                  {...{
                    catalog,
                    anchorEpdf,
                    setAnchorEpdf,
                    companySel,
                    setCatalogs,
                    setFilteredCatalogs,
                  }}
                />
              </li>
            ))}
          </ul>
          {filteredCatalogs?.length === 0 && (
            <div
              className="no-data-wrapper no-ideas-yet"
              aria-label="empty-state"
            >
              <em className="thumb">
                <img src={images.emptyCatalogue} alt="Spaces Flag" />
              </em>
              <h4>
                {catalogs?.length === 0
                  ? "You haven't created any templates yet"
                  : 'No templates found'}
              </h4>
              <p>
                Templates are created from an existing buildcard. Any templates{' '}
                <br />
                you create will be displayed here.
              </p>
            </div>
          )}
          {totalPages > 1 && (
            <div
              className="custom-pagination-wrapper"
              style={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
              }}
            >
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                siblingCount={2}
                shape="rounded"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Catalog;
