import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import * as React from 'react';

import CreditHistory from './CreditHistory';
import CreditOverview from './CreditOverview';
import ManageCredit from './ManageCredit';
import './credits.scss';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UnauthorizedAccess from '../../../components/UnauthorizedAccess';
import instance from '../../../shared/interceptor';
import {
  companyState,
  permissionState,
} from '../../../shared/store/slices/userSlice';
import { COMPANY } from '../../../shared/util/constant';
import {
  globalError,
  isAllowedTo,
  setLoader,
  transNavigation,
} from '../../../shared/util/util';
import { useNavigate } from 'react-router-dom';
import RightPageHeader from '../../../shared/shared-comp/RightPageHeader';

function CompanyCredits() {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const permissions = useSelector(permissionState);
  const navigate = useNavigate();

  const [value, setValue] = React.useState('1');
  const [spaceCredits, setSpaceCredits] = useState(null);
  const [plan, setPlan] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [searchSpaceCredits, setSearchSpaceCredits] = useState(null);
  const [emptyStateOverview, setEmptyStateOverview] = useState(false);
  const [creditPlanLoading, setCreditPlanLoading] = useState(true);
  const [spaceCreditLoading, setSpaceCreditLoading] = useState(true);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const getCreditPlanSub = async () => {
    try {
      setCreditPlanLoading(true);
      const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}`;
      const res = await instance.get(url);
      setPlan(res?.['plan_credit_subscription']);
      if (res && !res?.['plan_credit_subscription']?.['allocated_credits']) {
        setEmptyStateOverview(true);
      }
      let allocationGraphData = [
        {
          name: `${res?.['plan_credit_subscription']?.credit_balance} Credit balance`,
          color: '#F3EBFF',
          borderColor: '#F3EBFF',
          borderWidth: 1,
          data: [res?.['plan_credit_subscription']?.credit_balance],
        },
        {
          name: `${res?.['plan_credit_subscription']?.allocated_credits} Credits allocated`,
          data: [res?.['plan_credit_subscription']?.allocated_credits],
          color: '#D9C4FF',
          borderColor: '#D9C4FF',
          borderWidth: 1,
        },
        {
          name: `${res?.['plan_credit_subscription']?.consumed_credits} Credits used`,
          data: [res?.['plan_credit_subscription']?.consumed_credits],
          color: '#B388FF',
          borderColor: '#B388FF',
          borderWidth: 1,
        },
      ];
      setGraphData(allocationGraphData);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setCreditPlanLoading(false);
    }
  };

  const getSpaceCredit = async () => {
    try {
      setSpaceCreditLoading(true);
      const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/space_credits`;
      const res = await instance.get(url);
      setSpaceCredits(
        res?.['space_credits'].filter((item) => item?.space?.archive == false)
      );
      setSearchSpaceCredits(res?.['space_credits']);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setSpaceCreditLoading(false);
    }
  };

  //=========================================
  return (
    <>
      {compSel?.active_plan_credit_subscription?.id &&
      isAllowedTo('read', 'plan_credit_subscription', permissions) ? (
        <div
          className="credits-page credits-breacrumb"
          aria-label="company-credit"
        >
          <div className="page-container top-space">
            <div className="main-page-header">
              <div className="page-breacrumb">
                <div className="breadcrumb-wrapper">
                  <ul className="breadcrumb-links">
                    <li>
                      <a
                        onClick={() =>
                          transNavigation(
                            React.startTransition,
                            navigate,
                            '/company/company-settings'
                          )
                        }
                      >
                        Company
                      </a>
                    </li>
                    <li>Credits</li>
                  </ul>
                </div>
                <RightPageHeader />
              </div>
            </div>
            <div className="page-filters-wrapper secondary-header">
              <div className="left-filter">
                <div className="page-title">
                  <h2 aria-label="member-heading">Credits </h2>
                  <p>View and manage company wide credits of your account.</p>
                </div>
              </div>
              <div className="credits-right-filter">
                {/* <Button className="border-btn">Upgrade plan</Button> */}
                <Button className="purple-btn">Top up credits</Button>
              </div>
            </div>

            <Box>
              <TabContext value={value}>
                <Box
                  className="page-top-navigation"
                  sx={{ borderBottom: 1, borderColor: 'divider' }}
                >
                  <TabList onChange={handleChange} aria-label="tab-list">
                    <Tab label="Overview" value="1" />
                    <Tab label="Manage Credits" value="2" />
                    <Tab label="History" value="3" />
                  </TabList>
                </Box>
                <TabPanel className="credits-content-block" value="1">
                  <CreditOverview
                    {...{
                      spaceCredits,
                      emptyStateOverview,
                      plan,
                      graphData,
                      searchSpaceCredits,
                      getCreditPlanSub,
                      getSpaceCredit,
                      setValue,
                      creditPlanLoading,
                      spaceCreditLoading,
                    }}
                  />
                </TabPanel>
                <TabPanel className="credits-content-block" value="2">
                  <ManageCredit
                    {...{
                      spaceCredits,
                      plan,
                      graphData,
                      getCreditPlanSub,
                      getSpaceCredit,
                      spaceCreditLoading,
                    }}
                  />
                </TabPanel>
                <TabPanel
                  className="credits-content-block credits-history"
                  value="3"
                >
                  <CreditHistory {...{ setValue }} />
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      ) : (
        <UnauthorizedAccess />
      )}
    </>
  );
}

export default CompanyCredits;
