import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import BuildcardComp from '../../shared/shared-comp/BuildcardComp';
import {
  getBcState,
  getCardLable,
  groupBy,
  snakeCaseToSentence,
} from '../../shared/util/util';

const FilteredSpaceOverview = ({
  buildcardList,
  entity,
  searchSpaceGoalList,
  updateGoals,
  setShowSearchTags,
  deleteTag,
  addTags,
  tagChangeHandler,
  updateBuildCardName,
  spaceGoals,
  tagList,
  filteredValue,
}) => {
  const getBuildcardListKeys = () => {
    return Object.keys(groupBy(buildcardList, 'state', true));
  };

  return (
    <div className="accordion-filtered-cards-wrap tab-scroll-spacing">
      <div className="accordion-wrapper">
        {filteredValue &&
          buildcardList &&
          getBuildcardListKeys()
            ?.filter((cardKeys) =>
              Object.keys(filteredValue)
                .filter(function (key) {
                  return filteredValue[key];
                })
                .includes(cardKeys)
            )
            ?.map((card, idx) => (
              <Accordion defaultExpanded key={idx}>
                <AccordionSummary
                  expandIcon={<em className="be-chevron-down toggle-down"></em>}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="status-running" // dynamic status colors
                  style={{ borderLeft: `0.4rem solid ${getBcState(card)}` }}
                >
                  <div className="accordion-title status-running">
                    <strong>{snakeCaseToSentence(getCardLable(card))}</strong> (
                    {groupBy(buildcardList, 'state', true)?.[card]?.length})
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className="grid-view-cards-wrapper"
                    data-testid="details"
                  >
                    <div className="data-container">
                      <div className="row">
                        {groupBy(buildcardList, 'state', true)?.[card]?.map(
                          (bc, index) => (
                            <div
                              className="col-xs-12 col-sm-6 col-lg-6 col-xl-4"
                              key={idx + index}
                            >
                              <BuildcardComp
                                {...{
                                  card: bc,
                                  entity,
                                  idx,
                                  spaceGoals,
                                  searchSpaceGoalList,
                                  updateGoals,
                                  setShowSearchTags,
                                  deleteTag,
                                  tagList,
                                  addTags,
                                  tagChangeHandler,
                                  updateBuildCardName,
                                  showThreeDots: true,
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
      </div>
    </div>
  );
};

export default FilteredSpaceOverview;
