import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import images from '../../../assets/images';
import instance from '../../../shared/interceptor';
import { getUserListWithoutPermission } from '../../../shared/services/common.service';
import { updateSpaceList } from '../../../shared/store/slices/companySlice';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { BULK_CREATE_SPACE, COMPANY } from '../../../shared/util/constant';
import {
  debounce,
  getFullname,
  getInitials,
  getRandom,
  globalError,
  setLoader,
  validateEmail,
} from '../../../shared/util/util';
import LoaderComp from '../../../shared/shared-comp/LoaderComp';

function CreateSpaceDialog({ CreateSpace, CreateSpaceDialogClose }) {
  const dispatch = useDispatch();
  const companySel = useSelector(companyState);

  const [formVal, setFormVal] = useState({
    name: '',
    email: '',
    private: false,
    invited: false,
    userName: '',
  });
  const [spaceOwnerList, setSpaceOwnerList] = useState([]);
  const [defaultVal, setDefaultVal] = useState(null);
  const [spaceList, setSpaceList] = useState([]);
  const [renderKey, setRenderKey] = useState(getRandom());
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const searchSpaces = async (ownerVal) => {
    if (ownerVal) {
      try {
        let res = await getUserListWithoutPermission(
          companySel,
          ownerVal,
          dispatch,
          setLoading
        );
        setSpaceOwnerList(res?.['users'] ?? []);
        if (!res?.['users'].length && validateEmail(ownerVal)) {
          checkUserPresence(ownerVal);
        } else {
          setDefaultVal(null);
        }
      } catch (e) {
        globalError(dispatch, e);
      } finally {
        setLoading(false);
      }
    } else {
      setSpaceOwnerList([]);
      setDefaultVal(null);
      setFormVal({ ...formVal, email: '' });
    }
  };

  const checkUserPresence = async (val) => {
    try {
      setLoading(true);
      const url = `${COMPANY}/${companySel?.id}/check_user_presence?email=${val}`;
      await instance.get(url);
      setDefaultVal(val);
    } catch (e) {
      setDefaultVal(null);
      globalError(dispatch, e);
    } finally {
      setLoading(false);
    }
  };

  const addToSpaceList = () => {
    if (!formVal.name) {
      return setError('Fill Space name.');
    }
    let findItem = spaceList.find((item) => item.name === formVal.name);
    if (findItem)
      return setError(`Already added a space with name "${formVal.name}"`);

    setError('');
    let tempList = [...spaceList];
    setSpaceList([...tempList, formVal]);
    resetState(false);
  };

  const resetState = (removeSpaceList = true) => {
    if (removeSpaceList) {
      setSpaceList([]);
    }
    setError('');
    setSpaceOwnerList([]);
    setFormVal({
      name: '',
      email: '',
      private: false,
      invited: false,
      userName: '',
    });
    setDefaultVal(null);
    setRenderKey(getRandom());
  };

  const removeOwner = (owner) => {
    let tempArr = [...spaceList];
    let idx = tempArr.findIndex(
      (item) => item.name === owner.name && item.email === owner.email
    );
    tempArr.splice(idx, 1);
    setSpaceList(tempArr);
  };

  const createSpace = async () => {
    try {
      setLoader(dispatch, true);
      let url = `${BULK_CREATE_SPACE.replace(':id', companySel?.id)}`;
      let payload = {
        spaces: spaceList.map((item) => ({
          name: item?.name,
          email: item?.email,
          private: item?.private,
          invited: item?.invited,
        })),
      };
      let res = await instance.post(url, payload);
      if (res?.['spaces']?.count) {
        dispatch(
          setSuccessMsg(
            `${res?.['spaces']?.count} Space/s created successfully.`
          )
        );
        CreateSpaceDialogClose(null, true);
        dispatch(updateSpaceList(true));
      } else {
        globalError(dispatch, {
          message: 'Space Name already exists, No spaces created',
        });
        CreateSpaceDialogClose();
      }
      resetState();
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  };

  const checkTextLength = (val) => {
    if (!val.trim().length) {
      setError('Space name should not be empty.');
    }
    if (val.trim().length > 50) {
      setError('Space name should not be greater than 50');
    }
  };

  const handleModalClose = (e, reason) => {
    if (reason && reason == 'backdropClick') return;
    CreateSpaceDialogClose(e, false);
  };

  const handleEmailChange = debounce((e) => {
    setOpen(true);
    searchSpaces(e.target.value);
    setFormVal({ ...formVal, email: e.target.value });
  });

  //==============================================================

  return (
    <Dialog open={CreateSpace} onClose={handleModalClose}>
      <DialogContent>
        {/* <span className="modal-close be-close" onClick={() => {resetState(); CreateSpaceDialogClose() }}></span> */}
        <div className="modal-body" aria-label="create-space-dialog">
          <div className="modal-title has-right-data">
            <h2>Create spaces</h2>
          </div>

          <div className="modal-form">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <h6>Add a space</h6>
                  <FormControl
                    variant="filled"
                    className={error ? 'has-error' : ''}
                  >
                    <InputLabel>Space name</InputLabel>
                    {/* @ts-ignore */}
                    <FilledInput
                      autoComplete="off"
                      inputProps={{ 'data-testid': 'space-name' }}
                      type="text"
                      name="space-name"
                      value={formVal?.name}
                      onBlur={(e) => checkTextLength(e.target.value)}
                      onKeyUp={(e) => setError('')}
                      onChange={(e) =>
                        setFormVal({ ...formVal, name: e.target.value })
                      }
                    />
                    {/* TODO: add class for error className="has-error" */}
                  </FormControl>
                  <span aria-label="error" className="form-error">
                    {error}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group has-add-email">
                  <FormControl>
                    <Autocomplete
                      key={renderKey}
                      id="spaceOwnerEmail"
                      className="search-members-dropdown"
                      options={spaceOwnerList.map((item) => ({
                        id: item.id,
                        profile_picture:
                          item?.profile_picture?.profile_picture_url,
                        email: item.email,
                        name: getFullname(item?.first_name, item?.last_name),
                      }))}
                      autoHighlight
                      filterOptions={(options) => options}
                      disableClearable
                      onChange={(event, value) =>
                        setFormVal({
                          ...formVal,
                          email: value?.['email'],
                          userName: value?.['name'],
                        })
                      }
                      getOptionLabel={(option) =>
                        option?.['name'] ?? option?.['email']
                      }
                      open={open}
                      onBlur={() => setOpen(false)}
                      noOptionsText={
                        loading ? (
                          <LoaderComp
                            borderLessStyle={{
                              'max-width': '5%',
                              margin: '1rem 0.6rem',
                            }}
                          />
                        ) : (
                          <div style={{ margin: '0.3rem 0' }}>
                            No Owner found
                          </div>
                        )
                      }
                      renderOption={(props, option) => (
                        <Box component="span" {...props} key={option?.id}>
                          <div className="search-members-dropdown search-space-owner">
                            <div className="left-side">
                              {option?.profile_picture ? (
                                <img
                                  className="initials"
                                  src={option?.profile_picture}
                                  alt=""
                                />
                              ) : (
                                <span className="initials">
                                  {getInitials(option?.name) ?? (
                                    <em className="icon be-user"></em>
                                  )}
                                </span>
                              )}
                              <span className="user-details">
                                <span className="name">{option?.name}</span>
                                <span className="email">{option?.email}</span>
                              </span>
                            </div>
                          </div>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            'data-testid': 'autocomplete-search',
                          }}
                          className="search-input-box"
                          label="Space owner"
                          variant="outlined"
                          onKeyUp={(e) => setError('')}
                          onChange={handleEmailChange}
                        />
                      )}
                    />
                  </FormControl>

                  {defaultVal && validateEmail(defaultVal) && (
                    <div
                      className="add-email-wrapper invite"
                      onClick={() => {
                        setFormVal({
                          ...formVal,
                          email: defaultVal,
                          invited: true,
                        });
                        setDefaultVal(null);
                        setError('');
                      }}
                    >
                      <div className="add-email-box">
                        <strong>
                          <span className="primary-link">
                            Invite {defaultVal}
                          </span>
                        </strong>{' '}
                        to Builder Enterprise and the space "{formVal?.name}".
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <div className="has-right-data checkbox-container">
                    <FormControlLabel
                      className="checkbox-control"
                      control={
                        <Checkbox
                          data-testid="checkbox"
                          checked={formVal?.private ?? false}
                          aria-label="checkbox"
                          style={{ marginLeft: '2px' }}
                          icon={<img src={images.checkboxUnchecked} alt="" />}
                          checkedIcon={
                            <img src={images.checkboxChecked} alt="" />
                          }
                          onChange={(e) =>
                            setFormVal({
                              ...formVal,
                              private: !formVal?.private,
                            })
                          }
                        />
                      }
                      label={
                        <span className="make-private-label">
                          Make private
                          <Tooltip
                            classes={{ popper: 'info-tooltip' }}
                            arrow
                            placement="top"
                            title="Private spaces are displayed on the space listing page but can only be clicked by the respective space owner. Access to private spaces is also available in the navigation menu exclusively for the respective space owner."
                          >
                            <em className="be-info"></em>
                          </Tooltip>
                        </span>
                      }
                    />
                    <div className="right-title">
                      <Button
                        variant="contained"
                        aria-label="add-btn"
                        disabled={
                          !!error ||
                          !formVal?.name ||
                          (formVal?.private && !formVal?.email) ||
                          (!!formVal?.email && !validateEmail(formVal?.email))
                        }
                        className="xs-btn grey-btn"
                        onClick={addToSpaceList}
                      >
                        Add space
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!spaceList.length && <p>No spaces added yet. </p>}
          <div className="spaces-list-container">
            <ul>
              {spaceList.map((item) => {
                return (
                  <li
                    key={item?.name + getRandom()}
                    aria-label="list-item-space"
                  >
                    <div className="description">
                      <strong>{item?.name}</strong>
                      <div className="email">
                        {item?.invited
                          ? item?.email
                          : (item?.userName ?? item?.email)}{' '}
                        {item?.invited ? '(To be invited)' : ''}
                      </div>
                    </div>
                    <span className="icon-delete">
                      <img
                        aria-label="delete"
                        src={images.deleteIconBlue}
                        alt="delete icon"
                        onClick={(e) => removeOwner(item)}
                      />
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button
            variant="contained"
            className="sm-btn grey-btn"
            onClick={() => {
              resetState();
              CreateSpaceDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            className="sm-btn green-btn"
            aria-label="create-space"
            variant="contained"
            disabled={!!error || !spaceList?.length}
            onClick={createSpace}
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default CreateSpaceDialog;
